import React from 'react'
import moment from 'moment'
import { Fragment } from 'react'
import { Dialog, DialogPanel, DialogTitle, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

const SlideOut = ({ open, setOpen, audit }) => {

  if (!audit) {
    return null;
  }

  const safeJsonParse = (jsonString) => {
    if (!jsonString) return {};
    try {
      return JSON.parse(jsonString)
    } catch (error) {
      console.error('Invalid JSON string:', error)
      return {}
    }
  }

  const formatKey = (key) => {
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const getEventClassName = (event) => {
    switch (event) {
      case 'created':
        return 'bg-green-400 text-green-800';
      case 'updated':
        return 'bg-blue-400 text-blue-800';
      case 'deleted':
        return 'bg-red-400 text-red-800';
      default:
        return '';
    }
  }

  const formatNestedJsonAsList = (jsonObj) => {
    const entries = Object.entries(jsonObj)
    return (
      <div className="">
        {entries.map(([key, value]) => (
          <div key={key} className="break-words">
            <strong>{formatKey(key)}:</strong> {value !== null && typeof value === 'object' ? formatNestedJsonAsList(value) : String(value)}
          </div>
        ))}
      </div>
    )
  }

  const formatValue = (key, value) => {
    if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
      try {
        const parsedValue = JSON.parse(value)
        if (typeof parsedValue === 'object' && parsedValue !== null) {
          return formatNestedJsonAsList(parsedValue) // Format the nested JSON as a list
        }
      } catch (e) {
        // Value is not a JSON string, return as is
      }
    }
    return value !== null ? String(value) : 'null';
  }

  const formatJsonAsTable = (oldValuesJson, newValuesJson) => {
    const oldValues = safeJsonParse(oldValuesJson)
    const newValues = safeJsonParse(newValuesJson)
    
    const allKeys = new Set([...Object.keys(oldValues), ...Object.keys(newValues)]);
    
    const rows = Array.from(allKeys).map(key => (
      <tr key={key}>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{formatKey(key)}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatValue(key, oldValues[key] || '')}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatValue(key, newValues[key] || '')}</td>
      </tr>
    ))

    return (
      <table className="min-w-full divide-y divide-gray-200 mt-10">
        <thead>
          <tr>
            <th className="px-6 py-3 bg-gray-100 rounded-tl-lg text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Key</th>
            <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Old Value</th>
            <th className="px-6 py-3 bg-gray-100 rounded-tr-lg text-left text-xs font-medium text-gray-500 uppercase tracking-wider">New Value</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative" onClose={setOpen} style={{zIndex: 999}}>
        <Transition
          as={Fragment}
          enter="ease-in-out duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-blue-500 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-white">Viewing the Audit Log for {moment(audit.updated_at ?? '').format('DD/MM/YYYY')}</DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-blue-500 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className='px-4 py-2 bg-gray-100 rounded-lg mb-4 flex justify-between items-center shadow'>
                        <div>{audit.user_name}</div>
                        <div className={`status ${getEventClassName(audit.event)} capitalize`}>
                            {audit.event}
                        </div>
                      </div>
                      {formatJsonAsTable(audit.old_values, audit.new_values)}
                    </div>
                  </div>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default SlideOut
