import logo from '../img/swb-logo.png'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import axios from '../api/axios'
import Loader from '../components/Loader'
import moment from 'moment'
import { AddToCalendarButton } from 'add-to-calendar-button-react'

function ConfirmAssessment() {
  const { token } = useParams()

  const [confirmAssessment, setConfirmAssessment] = useState({})
  const [success, setSuccess] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [decline, setDeclined] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getAssessmentOption()
  }, [])

  const { setShowLoader, showLoader } = useAuth()

  const getAssessmentOption = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/get-assessment/${token}`)

      if (response.data.message === 'Invalid token') {
        setError(true)
        setShowLoader(false)
        return
      }

      if (response.data.result) {
        await setConfirmAssessment(response.data.result)

        setShowLoader(false)
        if (response.data.result.confirmed) {
          setSuccess(true)
          setRejected(false)

          return
        }

        if (response.data.result.rejected) {
          setRejected(true)
          setSuccess(false)

          return
        }
      } else {
        setShowLoader(false)

        declineSession()

        return
      }
    } catch (error) {
      console.log(error)
    }
  }

  const confirmSession = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/confirm-assessment/${token}`)
      if (!response.data.error) {
        await setSuccess(true)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const declineSession = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/reject-assessment/${token}`)
      if (!response.data.error) {
        await setDeclined(true)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  return (
    <div>
      {showLoader && <Loader />}
      <div className="flex justify-center mt-16">
        <img src={logo} alt="" className="w-1/4" />
      </div>
      <div className="max-w-xl mx-4 md:mx-auto mt-10  bg-gray-100 rounded-lg shadow-md p-4">
        {rejected && (
          <div>
            <div>
              <h1 className="font-semibold text-rose-700 text-2xl text-center pt-8 pb-8">
                We are sorry, this appointment is no longer available.
              </h1>
              <p className="text-center pb-4 text-lg font-semibold">
                One of our practitioners will be in touch soon.
              </p>
            </div>
          </div>
        )}

        {error && (
          <div>
            <div>
              <p className="text-center py-8 text-5xl text-pink-700 font-semibold">
                404
              </p>
              <p className="text-center text-gray-800 pb-4">
                Oops! We are not able to validate your request at this time.
              </p>
            </div>
          </div>
        )}

        {success ? (
          <div>
            <div>
              <div>
                <div className="flex justify-center font-semibold text-teal-500 text-2xl text-center pt-8 px-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-20 h-20"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                    />
                  </svg>
                </div>
                <h1 className="font-semibold text-teal-500 text-2xl text-center pt-4 pb-8 px-6">
                  {confirmAssessment.client.name} your appointment is confirmed!
                </h1>
                <p className="text-center py-6 text-lg font-semibold">
                  {' '}
                  {moment(confirmAssessment.start_time).format('hh:mma')} -{' '}
                  {moment(confirmAssessment.end_time).format('hh:mma')} |{' '}
                  {confirmAssessment.user.name}
                </p>
                <p className="text-gray-800 text-center py-4">
                  We are looking forward to seeing you on{' '}
                  {moment(confirmAssessment.end_time).format(
                    'dddd Do MMM YYYY'
                  )}
                </p>

                <div className="flex justify-center py-6">
                  <AddToCalendarButton
                    name={`Wellbeing Assessment - ${confirmAssessment.service.title}`}
                    options={['Apple', 'Google', 'Outlook.com', 'iCal']}
                    location={`Wellbeing Assessment - ${confirmAssessment.service.title}`}
                    startDate={moment(confirmAssessment.end_time).format(
                      'YYYY-MM-DD'
                    )}
                    startTime={moment(confirmAssessment.start_time).format(
                      'HH:mm'
                    )}
                    endTime={moment(confirmAssessment.end_time).format('HH:mm')}
                    timeZone="Europe/London"
                  ></AddToCalendarButton>
                </div>

                <hr />
                <p className="text-xs pt-4">
                  If you can no longer make this appointment, please get in
                  touch with your practitioner.
                </p>
                <p className="text-xs">
                  Please give 24 hours notice for any changes or cancellations,
                  contact us on <a href="tel:01773814402">01773 814 402</a> or
                  email{' '}
                  <a href="mailto:referrals@schoolwellbeing.com">
                    referrals@schoolwellbeing.com
                  </a>
                  .
                </p>
                <p className="text-xs">
                  Please Note: Cancellations within 24 hours will be charged.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!rejected && !error && (
              <div>
                {confirmAssessment?.id && !rejected ? (
                  <div>
                    <div className="font-semibold text-pink-700 text-xl text-center py-8">
                      Are you sure you want to confirm this assessment?
                    </div>
                    <div className="pl-4 space-y-4 text-gray-800">
                      <div>
                        Clinician:{' '}
                        <span className="font-semibold">
                          {confirmAssessment.user.name}
                        </span>
                      </div>
                      <div>
                        Service:{' '}
                        <span className="font-semibold">
                          {confirmAssessment.service.title}
                        </span>
                      </div>
                      <div>
                        Appointment Date:{' '}
                        <span className="font-semibold">
                          {moment(confirmAssessment.end_time).format(
                            'ddd Do MMM'
                          )}
                        </span>
                      </div>
                      <div>
                        Appointment Time:{' '}
                        <span className="font-semibold">
                          {moment(confirmAssessment.start_time).format(
                            'hh:mma'
                          )}{' '}
                          -{' '}
                          {moment(confirmAssessment.end_time).format('hh:mma')}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-end space-x-4 pt-6 py-1">
                      {/* <button
         onClick={declineSession}
         className="border border-pink-800 bg-pink-700 text-white font-semibold rounded-md px-3 py-1 hover:bg-white hover:text-pink-700"
       >
         No, find me another appointment
       </button> */}
                      <button
                        onClick={confirmSession}
                        className="border border-pink-800 bg-pink-700 text-white font-semibold rounded-md px-3 py-1 hover:bg-white hover:text-pink-700"
                      >
                        YES
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="font-semibold text-pink-700 text-xl text-center py-8">
                        We are sorry you could not make any of these appointment
                        dates.
                      </div>
                      <div className="space-y-2 px-4">
                        <p>
                          A practitioner will be in touch shortly with new
                          appointment dates.
                        </p>
                        <p>You may now close your browser.</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ConfirmAssessment
