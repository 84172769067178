import moment from 'moment'
import useAuth from '../hooks/useAuth'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Modal from './Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../components/Pagination'

function PayslipAdjustments({
  data = [],
  selectedPractitioner,
  setAdjustmentData,
}) {
  const { can, setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()

  const [addAdjustmentFlag, setAddAdjustmentFlag] = useState(false)
  const [deleteAdjustment, setDeleteAdjustment] = useState(0)
  const [deleteAdjustmentFlag, setDeleteAdjustmentFlag] = useState(false)
  const [newAdjustment, setNewAdjustment] = useState({})

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const ModalBody = () => {
    if (addAdjustmentFlag) {
      return <AddAdjustmentElement />
    }

    if (deleteAdjustmentFlag) {
      return <RemoveAdjustmentElement />
    }
  }

  useEffect(() => {
    if (data) {
      setFilteredRecords(data)
      setCurrentRecords(
        data.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(data.length / recordsPerPage))
    }
  }, [data])

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }


  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }


  const addAdjustmentClicked = () => {
    setAddAdjustmentFlag(true)
    setModalTitle(`Add Adjustment`)
    setShowModal(true)
  }

  const deleteAdjustmentClicked = (id) => {
    setDeleteAdjustmentFlag(true)
    setDeleteAdjustment(id)
    setModalTitle(`Delete Adjustment`)
    setShowModal(true)
  }

  const AddAdjustmentElement = () => {
    const addAdjustment = async (e) => {
      e.preventDefault()
      if(!selectedPractitioner)
      {
        createInfo('error', `Please select a practitioner`)
        return
      }
      setShowLoader(true)
      try {
        const response = await axios.post('/adjustments/create', {
          user_id: selectedPractitioner.id,
          reason: newAdjustment.reason,
          amount: newAdjustment.amount,
          date: newAdjustment.date,
        })
        if (response.data.error === false) {
          createInfo('success', `Adjustment Added`)
          setAdjustmentData([...data, response.data.result])
          setAddAdjustmentFlag(false)
          setNewAdjustment({})
          resetModal()
        }
      } catch (error) {
        createInfo('error', `Failed to add adjustment`)
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={addAdjustment}>
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Reason for Adjustment
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              defaultValue={newAdjustment.reason}
              required
              name="reason"
              id="reason"
              onChange={(e) => (newAdjustment.reason = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="date"
            className="block font-medium text-gray-600 text-sm"
          >
            Date
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={newAdjustment.date}
              required
              type="date"
              name="date"
              id="date"
              onChange={(e) => (newAdjustment.date = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="amount"
            className="block font-medium text-gray-600 text-sm"
          >
            Amount
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              defaultValue={newAdjustment.amount}
              required
              type="number"
              name="amount"
              id="amount"
              step=".01"
              onChange={(e) => (newAdjustment.amount = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Add Adjustment</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const RemoveAdjustmentElement = () => {
    const removeService = async (e) => {
      setShowModal(false)
      setShowLoader(true)
      await axios.get(`/adjustments/delete/${deleteAdjustment}`)
      createInfo('error', `Adjustment Deleted`)
      setAdjustmentData(
        data.filter((adjustment) => {
          return adjustment.id !== deleteAdjustment
        })
      )
      setDeleteAdjustment(0)
      setDeleteAdjustmentFlag(false)
      setShowLoader(false)
    }
    return (
      <form onSubmit={removeService} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to remove this adjustment?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Delete Adjustment
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = () => {
    setAddAdjustmentFlag(false)
    setDeleteAdjustmentFlag(false)
    setDeleteAdjustment(0)
    setNewAdjustment({})
    setShowModal(false)
    setModalTitle('')
  }

  return (
    <>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className="w-full py-4">
        <div className="flex items-center justify-between">
          <div className="text-lg font-semibold">Adjustments</div>
          {can('add adjustment') && selectedPractitioner !== 0 && (
            <button
              onClick={addAdjustmentClicked}
              className="rounded-lg bg-blue-500 text-white px-4 py-2 hover:bg-blue-700 text-base"
            >
              Add Adjustment
            </button>
          )}
        </div>
        <div className="overflow-hidden rounded-lg mt-4">
          <table className="table-main">
            <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
              <tr>
                <th scope="col" className="px-6 py-4">
                  Date
                </th>
                <th scope="col" className="px-6 py-4">
                  Reason
                </th>
                <th scope="col" className="px-6 py-4">
                  Adjustment ID
                </th>
                <th scope="col" className="px-6 py-4">
                  Amount
                </th>
                {can('delete adjustment') && (
                  <th scope="col" className="px-6 py-4">
                    Delete
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords.map((item, index) => {
                  const start = moment(item.start_time)
                  const end = moment(item.end_time)
                  const duration = moment
                    .duration(end.diff(start))
                    .asMinutes()
                    .toFixed(2)
                  return (
                    <tr
                      key={index}
                      className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                    >
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {moment(item.date).format('DD/MM/YY')}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.reason}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.id}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.amount}
                      </td>
                      {can('delete adjustment') && (
                        <td className="whitespace-nowrap px-6 py-4 font-medium flex justify-center">
                          <span
                            title="Delete Adjustment"
                            onClick={() => deleteAdjustmentClicked(item.id)}
                            className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </td>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <p className="no-records">No Adjustments found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
          next={nextPage}
          prev={previousPage}
          first={indexOfFirstRecord}
          last={indexOfLastRecord}
          total={filteredRecords.length}
        />
        </div>
      </div>
    </>
  )
}

export default PayslipAdjustments
