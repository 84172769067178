import React, { Fragment, useState, useEffect, useMemo } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import moment from 'moment'
import Modal from '../components/Modal'
import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronUp, faChevronDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import Datepicker from 'react-tailwindcss-datepicker'
import { TimePicker } from '@hilla/react-components/TimePicker.js';


function BookSession({
  claimData = null,
  sessions = [],
  setBookSession,
  insurerHistory,
}) {
  const { auth, setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [availabilities, setAvailabilities] = useState([])
  const [availabilitiesCache, setAvailabilitiesCache] = useState([])
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({})
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState('00:00-23:59')
  const [selectedPractitioner, setSelectedPractitioner] = useState(0)
  const [availabilitiesLoaded, setAvailabilitiesLoaded] = useState(false)
  const [showAllAvailable, setShowAllAvailable] = useState(false)
  const [videoSession, setVideoSession] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [hasSessions, setHasSessions] = useState(true)
  const [sortCriteria, setSortCriteria] = useState('date')
  const [bookingData, setBookingData] = useState({})
  const [availabilityPercentages, setAvailabilityPercentages] = useState({})  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().add(7, 'days').toDate());
  const [selectedHour, setSelectedHour] = useState('')
  const [customStartHour, setCustomStartHour] = useState('');
  const [customEndHour, setCustomEndHour] = useState('');
 
  const sortOptions = [
    { id: 1, name: 'Order By Date', value: 'date' },
    { id: 2, name: 'Order By Percentage Booked', value: 'percentageBooked' }
  ];

  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0])
  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const [session, setSession] = useState({
    date: moment().format('YYYY-MM-DD'),
    service: 0,
    allUsers: true,
  })
  
  const loadAvailability = async () => {
    if (!startDate || !endDate) {
      setDateError(true);
      return;
    }
    setVideoSession(false)
    setDateError(false);
    setAvailabilitiesLoaded(false)
    setSelectedPractitioner(0)
    setShowAllAvailable(true)
    setSelectedTimeSlot([])
    setShowLoader(true)
  
    const { start, end } = getTimeRange()
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    try {
      const response = await axios.get(
        `/availabilities/${formattedStartDate}/${formattedEndDate}/${claimData.service.id}/${session.allUsers}/${start}/${end}`
      );
      if (!response.data.error) {
        setAvailabilitiesLoaded(true)
        const loadedAvailabilities = response.data.result
        await calculateAndGroupAvailabilities(loadedAvailabilities)
      }
    } catch (error) {
      console.error(error);
    }
    setShowLoader(false)
  };  
  
  const getTimeRange = () => {
    if (selectedTimeOfDay) {
      if (selectedTimeOfDay === 'custom') {
        if (!customEndHour && customStartHour) {
          const startHourInt = parseInt(customStartHour.split(':')[0], 10);
          const defaultEndHour = startHourInt + 1 <= 23 ? `${startHourInt + 1}:00` : '23:59';
          setCustomEndHour(defaultEndHour);
          return { start: customStartHour, end: defaultEndHour };
        }
        return { start: customStartHour || '00:00', end: customEndHour || '23:59' };
      }
      return timeOfDayRanges[selectedTimeOfDay];
    }
    if (selectedHour !== '') {
      const hour = parseInt(selectedHour, 10);
      return { start: `${hour}:00`, end: `${hour + 1}:00` };
    }
    return { start: "00:00", end: "23:59" };
  };

  const timeOfDayRanges = {
    "00:00-23:59": { start: "00:00", end: "23:59" },
    "00:00-11:59": { start: "00:00", end: "11:59" },
    "12:00-16:59": { start: "12:00", end: "16:59" },
    "17:00-23:59": { start: "17:00", end: "23:59" },
  };  

  const sortPractitionersByBookingPercentage = (practitioners, percentages) => {
    return practitioners.slice().sort((a, b) => {
      const percentageA = percentages[a.user.id] || 0;
      const percentageB = percentages[b.user.id] || 0;
      return percentageA - percentageB;
    });
  };

  const sortPractitionersByDate = (practitioners) => {
    return practitioners.slice().sort((a, b) => {
      const earliestSlotA = Math.min(...a.slots.map(slot => new Date(slot).getTime()));
      const earliestSlotB = Math.min(...b.slots.map(slot => new Date(slot).getTime()));
      return earliestSlotA - earliestSlotB;
    });
  };

  const sortAvailabilities = (availabilities, percentages) => {
    if (sortCriteria === 'date') {
      return sortPractitionersByDate(availabilities);
    } else {
      return sortPractitionersByBookingPercentage(availabilities, percentages);
    }
  };

  const sortedAvailabilities = useMemo(() => {
    return sortAvailabilities(availabilities, availabilityPercentages);
  }, [sortCriteria, availabilities, availabilityPercentages]);
  
  const calculateAndGroupAvailabilities = async (loadedAvailabilities) => {
    try {
      const percentageResponse = await axios.post(`/claims/calculate-availability`, session);
      if (!percentageResponse.data.error) {
        setAvailabilityPercentages(percentageResponse.data);
        const sortedAvailabilities = sortAvailabilities(
          loadedAvailabilities,
          percentageResponse.data
        );
        const groupedAvailabilities = groupAvailabilitiesByDay(sortedAvailabilities);
        setAvailabilities(groupedAvailabilities);
        setAvailabilitiesCache(groupedAvailabilities);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const groupAvailabilitiesByDay = (availabilities) => {
    let arr = {};
    let practitioners = [];
    availabilities.forEach((a) => {
      let days = {};
      let timeSlots = {};
      a.slots.forEach((slot) => {
        const formattedDate = moment(slot).format('DD/MM/YYYY')
        if (days[formattedDate]) {
          days[formattedDate].push(slot)
        } else {
          days[formattedDate] = [slot]
        }
  
        if (!timeSlots[formattedDate]) {
          timeSlots[formattedDate] = 0;
        }
      });
      arr[a.user.name] = timeSlots;
      a.days = days;
      practitioners.push(a);
    });
    setSelectedTimeSlot(arr);
    return practitioners;
  };

  const bookSessionClicked = () => {
    setModalTitle(`Cannot Book Session`)
    setShowModal(true)
  }

  const resetModal = () => {
    setShowModal(false)
  }

  const checkHasSessions = () => {
    const sessionsRemaining =
      claimData.session_count - claimData.used_sessions.length

    if (sessionsRemaining > 0) {
      return true
    }

    return false
  }

  const updateAvailabilities = (checked) => {
    if (!checked) {
      setAvailabilities(availabilitiesCache)
      setAvailabilitiesCache(availabilitiesCache)
      return
    }

    let filteredAvailabilities = availabilities.filter((availability) => {
      return availability.user.video_sessions === 1;
    })

    setAvailabilities(filteredAvailabilities)
    setAvailabilitiesCache(filteredAvailabilities)
    return;
  }

  const calcPercentAvailable = async () => {
    setShowLoader(true)
    try {
      const response = await axios.post(
        `/claims/calculate-availability`,
        session
      )

      if (!response.data.error) {
        setAvailabilityPercentages(response.data)
      } else {
        createInfo('error', 'Could not fetch practitioner availability.')
      }
    } catch (error) {
      createInfo('error', 'An error occurred while processing your request.')
    }
    setShowLoader(false)
  }

  const handleSortChange = (option) => {
    setSelectedSortOption(option);
    setSortCriteria(option.value);
  };

  const NoSessionsElement = () => {
    const add = async (e) => {
      e.preventDefault()
      resetModal()
      setShowLoader(true)
      const data = {
        date: moment(bookingData.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        start: moment(bookingData.time).format('HH:mm'),
        end: moment(bookingData.time)
          .add(claimData.service.default_length, 'minutes')
          .format('HH:mm'),
        user: bookingData.user,
        service: claimData.service,
      }

      try {
        const response = await axios.post(
          `/claims/${claimData.id}/session/add`,
          data
        )
        if (!response.data.error) {
          sessions.push(response.data.result)
          createInfo(
            'success',
            `Session booked for ${bookingData.day} ${moment(
              bookingData.time
            ).format('HH:mm')}`
          )
          setBookSession(false)
        }
      } catch (error) {
        console.log(error)
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={add} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            This client has no remaining sessions.
          </p>
          <div className="flex mt-2 w-full">
            {(auth.user.type_id === 1 || auth.user.type_id === 2) && (
              <button className="btn red mt-4 mr-4 w-1/2">Book Session</button>
            )}
            <button
              type="button"
              className={
                auth.user.type_id === 1 || auth.user.type_id === 2
                  ? 'btn mt-4 w-1/2 bg-blue-500'
                  : 'btn mt-4 w-full'
              }
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const ModalBody = () => {
    if (!hasSessions) {
      return <NoSessionsElement />
    }
  }

  const addSession = async (user, day, time) => {
    if (time === 0) {
      createInfo('error', 'Please select a time slot')
      return
    }

    setShowLoader(true);
    let bookingInfo = {
      day: day,
      user: user,
      time: time,
    };
  
    setBookingData(bookingInfo);
    let canBook = checkHasSessions()
    if (!canBook) {
      setShowLoader(false)
      setHasSessions(false)
      bookSessionClicked()
      return
    }
    const data = {
      date: moment(bookingInfo.day, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      start: moment(bookingInfo.time).format('HH:mm'),
      end: moment(bookingInfo.time)
        .add(claimData.service.default_length, 'minutes')
        .format('HH:mm'),
      user: user,
      service: claimData.service,
    }

    try {
      const response = await axios.post(
        `/claims/${claimData.id}/session/add`,
        data
      )
      if (!response.data.error) {
        sessions.push(response.data.result)
        createInfo(
          'success',
          `Session booked for ${bookingInfo.day} ${moment(
            bookingInfo.time
          ).format('HH:mm')}`
        )
        setBookSession(false)
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const ShowAvailability = ({ data, specificNeeds }) => {
    let hasSpeciality = []
    if (data.user.specialities.length > 0) {
      specificNeeds.forEach((need) => {
        if (
          data.user.specialities.some((speciality) => {
            return speciality.id === need.id
          })
        ) {
          hasSpeciality++
        }
      })
    }

    return (
      <div className="p-4 rounded-md border border-slate-300 mt-4">
        <div className="flex">
          {data.user.video_sessions === 1 && (
            <div className="group w-7 h-7 fill-slate-800 cursor-pointer relative mr-2 z-10">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
              </svg>
              <span
                className={`absolute top-8 scale-0 transition-all font-normal rounded bg-gray-800 p-2 text-sm text-white group-hover:scale-100 -left-7 min-w-[300px]`}
              >
                This practitioner does video sessions
              </span>
            </div>
          )}
          {hasSpeciality > 0 && (
            <div className="group w-7 h-7 fill-yellow-500 cursor-pointer relative mr-2 z-10">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM205.1 73.3c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3L123.3 187.3 9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5l114.1 52.7L176 435.8c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l52.7-114.1 114.1-52.7c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5L257.8 187.4 205.1 73.3zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z" />
              </svg>
              <span
                className={`absolute top-8 scale-0 transition-all font-normal rounded bg-gray-800 p-2 text-sm text-white group-hover:scale-100 -left-7 min-w-[300px]`}
              >
                This practitioner has {hasSpeciality} matching{' '}
                {hasSpeciality === 1 ? 'speciality' : 'specialities'}
              </span>
            </div>
          )}
          <div className="font-bold text-lg mb-2 flex space-x-1">
            <span>{data.user.name} </span>
            <div className="group w-7 h-7 cursor-pointer relative mr-2 z-10">
              <span className="text-sm">
                {availabilityPercentages[data.user.id] != null
                  ? `  (${availabilityPercentages[data.user.id]}%)`
                  : '(N/A)'}
              </span>
              <span
                className={`absolute top-8 scale-0 transition-all text-center font-normal rounded bg-gray-800 p-2 text-sm text-white group-hover:scale-100 -left-7 min-w-[200px]`}
              >
                Percentage booked
              </span>
            </div>
          </div>
        </div>

        <div className="flex">
          {Object.keys(data?.days || {}).map((key, index) => {
            return (
              <div key={index} className="w-[300px] relative mr-4">
                <h3 className="font-medium mb-1">
                  {moment(key, 'DD/MM/YYYY').format('dddd Do MMMM')}
                </h3>
                <div>
                  <label
                    htmlFor="service"
                    className="block font-medium text-gray-600 text-sm"
                  >
                    Time Slot
                  </label>
                  <select
                    defaultValue={
                      selectedTimeSlot[data.user.name] &&
                      selectedTimeSlot[data.user.name][key]
                        ? selectedTimeSlot[data.user.name][key]
                        : 0
                    }
                    className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                    required
                    onChange={(e) => {
                      if (!selectedTimeSlot[data.user.name]) {
                        selectedTimeSlot[data.user.name] = {};
                      }
                      selectedTimeSlot[data.user.name][key] = e.target.value;
                      setSelectedTimeSlot({ ...selectedTimeSlot });
                    }}
                    id="service"
                  >
                    <option disabled value={0}>
                      Select a Time
                    </option>
                    {data.days[key].map((slot) => {
                      return (
                        <option key={slot} value={slot}>
                          {moment(slot).format('HH:mm a')}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <button
                  className="btn green mt-4"
                  onClick={() => {
                    addSession(data.user, key, selectedTimeSlot[data.user.name][key]); 
                  }}
                >
                  Add Session
                </button>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (availabilities.length > 0) {
      const groupedAvailabilities = groupAvailabilitiesByDay(sortedAvailabilities);
      if (JSON.stringify(groupedAvailabilities) !== JSON.stringify(availabilities)) {
        setAvailabilities(groupedAvailabilities);
        setAvailabilitiesCache(groupedAvailabilities);
      }
    }
  }, [sortedAvailabilities]);

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <div className="max-w-lg">
        <h3 className="font-bold text-lg mb-2">Book a Session</h3>

        <div className="mb-3">
          <label
            htmlFor="service"
            className="block font-medium text-gray-600 text-sm"
          >
            Service
          </label>
          <input
            type="text"
            disabled
            value={claimData.service.title}
            className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          />
        </div>
      </div>
      <div>
        <div>
          <div className="max-w-lg">
            <div className="mb-3">
              <label htmlFor="date" className="block font-medium text-gray-600 text-sm">
                Week Start
              </label>
              <div className="mt-1 relative rounded-md border p-0.5 border-gray-300 shadow-md">
                <Datepicker
                  value={{ startDate, endDate }}
                  onChange={(value) => {
                    const { startDate: start, endDate: end } = value;
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  useRange={false}
                  primaryColor={'blue'}
                  minDate={moment().toDate()}
                  inline
                />
                {dateError && (
                  <p className="text-red-500 text-sm mt-1">This field is required</p>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="timeOfDay" className="block font-medium text-gray-600 text-sm">
                Time of day
              </label>
              <select
                className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                value={selectedTimeOfDay}
                onChange={(e) => {
                  setSelectedHour('');
                  setSelectedTimeOfDay(e.target.value);
                  setCustomStartHour('');
                  setCustomEndHour('');
                }}
                id="timeOfDay"
              >
                <option value="">Select Time of Day</option>
                <option value="00:00-23:59">All Day</option>
                <option value="00:00-11:59">Morning</option>
                <option value="12:00-16:59">Afternoon</option>
                <option value="17:00-23:59">Evening</option>
                <option value="custom">Custom</option>
              </select>
            </div>

            {selectedTimeOfDay === 'custom' && (
              <div className="mb-3">
                <label className="block font-medium text-gray-600 text-sm">
                  Select Custom Time Range
                </label>
                <div className="flex space-x-2">
                  <TimePicker
                    label="Start Time"
                    value={customStartHour}
                    onValueChanged={(e) => {
                      setCustomStartHour(e.detail.value);
                      setCustomEndHour('');
                    }}
                    min='07:00'
                    step={60 * 30}
                    className="mt-4"
                  />
                  <TimePicker
                    label="End Time"
                    value={customEndHour}
                    onValueChanged={(e) => setCustomEndHour(e.detail.value)}
                    step={60 * 30}
                    min={customStartHour}
                    max="23:59"
                    disabled={!customStartHour}
                    className="mt-4"
                  />
                </div>
              </div>
            )}
            <div className="w-full mr-2 flex items-center mb-2">
              <div className="relative mr-2">
                <input
                  type="checkbox"
                  id="get_all"
                  onChange={(e) => {
                    setAvailabilitiesLoaded(false);
                    setSelectedPractitioner(0);
                    setShowAllAvailable(false);
                    setSession((prev) => ({
                      ...prev,
                      allUsers: e.target.checked,
                    }));
                  }}
                  placeholder="All Practitioners"
                  className="field"
                  checked={session.allUsers}
                />
              </div>
              <label htmlFor="get_all" className="block font-medium text-gray-600 text-sm">
                All Practitioners
              </label>
            </div>

            <button className="btn" onClick={loadAvailability}>
              Load Availability
            </button>
          </div>
        </div>
        {availabilities.length > 0 && availabilitiesLoaded && (
          <div className="border-t border-slate-200 mt-4">
            <h3 className="font-bold text-lg mb-2 mt-4">
              Available Practitioners ({availabilities.length})
            </h3>
            <div className="flex flex-col">
              <div className="w-full mr-2 flex items-center mb-2">
                <div className="relative mr-2">
                  <input
                    type="checkbox"
                    id="show_all"
                    onChange={(e) => {
                      setSelectedPractitioner(0);
                      setShowAllAvailable(e.target.checked);
                      if (e.target.checked) {
                        calcPercentAvailable();
                      }
                    }}
                    placeholder="Show all Available"
                    className="field"
                    checked={showAllAvailable}
                  />
                </div>
                <label htmlFor="show_all" className="block font-medium text-gray-600 text-sm">
                  Show All Available Practitioners
                </label>
              </div>
              <div className=" w-full mr-2 flex items-center mb-2">
                <div className="relative mr-2">
                  <input
                    type="checkbox"
                    id="videoSession"
                    onChange={(e) => {
                      setVideoSession(e.target.checked);
                      updateAvailabilities(e.target.checked);
                    }}
                    placeholder="Show only Video Session Practitioners"
                    className="field"
                    checked={videoSession}
                  />
                </div>
                <label
                  htmlFor="videoSession"
                  className="block font-medium text-gray-600 text-sm"
                >
                  Video Session
                </label>
              </div>
              <div className="w-full">
                <div className="flex justify-end items-end space-x-2">
                  <div className="w-72">
                    <h2 className="block text-sm font-medium leading-6 text-gray-900">Sort Options</h2>
                    <Menu as="div" className="relative inline-block text-left w-full">
                        <div>
                          <MenuButton
                            className="flex items-center text-gray-900 font-medium leading-6 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 w-full justify-between"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          >
                            <span className="mr-2">{selectedSortOption.name}</span>
                            <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </MenuButton>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <MenuItems className="absolute mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="p-4">
                              {sortOptions.map((option) => (
                                <MenuItem key={option.id}>
                                  {({ focus }) => (
                                    <div
                                      onClick={() => handleSortChange(option)}
                                      className={`flex items-center cursor-pointer py-2 px-4 hover:bg-blue-400 rounded-lg ${
                                        focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                      } ${selectedSortOption.value === option.value ? 'font-semibold' : ''}`}
                                    >
                                      <div className="block truncate">{option.name}</div>
                                      {selectedSortOption.value === option.value && (
                                        <div className="ml-auto text-indigo-600">
                                          <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </MenuItem>
                              ))}
                            </div>
                          </MenuItems>
                        </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
              {!showAllAvailable && (
                <div className="w-[300px] relative mr-4">
                  <div className="mb-3">
                    <label htmlFor="service" className="block font-medium text-gray-600 text-sm">
                      Practitioner
                    </label>
                    <select
                      defaultValue={selectedPractitioner}
                      className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      required
                      onChange={(e) => setSelectedPractitioner(JSON.parse(e.target.value))}
                      id="service"
                    >
                      <option disabled value="0">
                        Select a Practitioner
                      </option>
                      {availabilities.map((availability) => (
                        <option key={availability.user.id} value={JSON.stringify(availability)}>
                          {availability.user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {selectedPractitioner > 0 && (
                <ShowAvailability
                  key={selectedPractitioner.user.id}
                  data={selectedPractitioner}
                  specificNeeds={claimData.specialities}
                />
              )}

              {showAllAvailable &&
                sortedAvailabilities.map((availability) => {
                  return (
                    <ShowAvailability
                      key={availability.user.id}
                      data={availability}
                      specificNeeds={claimData.specialities}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {availabilities.length <= 0 && availabilitiesLoaded && (
          <h3 className="font-bold text-lg mb-2 mt-4">No Available Practitioners</h3>
        )}
      </div>
    </div>
  )
}

export default BookSession
