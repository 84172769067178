import { useState } from 'react'
import MainTitle from '../../../components/MainTitle'
import { PhoneNumberUtil } from 'google-libphonenumber'

import { usePlacesWidget } from 'react-google-autocomplete'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const GOOGLE_API_KEY = 'AIzaSyDcTd3xEx_Y4ZlRo-7eeoSWzLUnWO67LFU'

function CreateClinic() {
  const axios = useAxiosPrivate()
  const { setShowLoader, createInfo } = useAuth()

  const navigate = useNavigate()

  const [validEmail, setValidEmail] = useState(true)
  const [clinicianData] = useState({})
  const [clinicData] = useState({})
  const [useClinicianDetails, setUseClinicianDetails] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isValid, setIsValid] = useState(false)

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target
    setPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance()

    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, '+44')
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber)
      return isValidNumber
    } catch (e) {
      console.error('Error parsing phone number:', e)
      return false
    }
  }

  const createClinic = async (e) => {
    e.preventDefault()
    setValidEmail(true)
    setShowLoader(true)
    //Check user email
    try {
      const response = await axios.get(`/user/by-email/${clinicianData.email}`)
      if (response.data.error === true) {
        setShowLoader(false)
        setValidEmail(false)
        return
      }
    } catch (error) {}

    const data = {
      user: {
        name: clinicianData.name,
        email: clinicianData.email,
        phone_number: clinicianData.phone_number.replace(/\s/g, ''),
        status_id: 1,
        type_id: 3,
      },
      company: {
        name: clinicData.name,
        address: clinicData.address,
        status_id: 1,
      },
    }
    if (useClinicianDetails) {
      data.company.main_contact_details = JSON.stringify({
        name: clinicianData.name,
        email: clinicianData.email,
        phone_number: clinicianData.phone_number,
      })
    } else {
      data.company.main_contact_details = JSON.stringify({
        name: clinicData.main_name,
        email: clinicData.main_email,
        phone_number: clinicData.main_number,
      })
    }

    try {
      const response = await axios.post('/companies/create', data)
      setShowLoader(false)
      if (response.data.error === false) {
        createInfo('success', `Created Clinic: ${clinicData.name}`)
        navigate(`/clinics/${response.data.result.id}`)
      }
    } catch (error) {
      setShowLoader(false)
    }
  }

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      clinicData.address = place.formatted_address
    },
    options: {
      types: [],
      componentRestrictions: { country: 'gb' },
    },
  })

  const resetMainDetails = () => {
    clinicData.main_email = ''
    clinicData.main_name = ''
    clinicData.main_number = ''
  }

  return (
    <section>
      <MainTitle title="Create Clinic" backButton />

      <section>
        <form className="w-full relative" onSubmit={createClinic}>
          <div>
            <h3 className="text-md font-bold mb-2">Clinician Info</h3>
            <div className="flex">
              <div className="mb-3 w-1/2 mr-2">
                <label
                  htmlFor="clinician_name"
                  className="block text-xs font-medium text-gray-600"
                >
                  Clinician Name
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    defaultValue={clinicianData.name}
                    type="text"
                    name="clinician_name"
                    required
                    id="clinician_name"
                    onChange={(e) => (clinicianData.name = e.target.value)}
                    className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  />
                </div>
              </div>
              <div className="mb-3 w-1/2 ml-2">
                <label
                  htmlFor="clinician_email"
                  className="block text-xs font-medium text-gray-600"
                >
                  Clinician Email
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    defaultValue={clinicianData.email}
                    type="clinician_email"
                    required
                    name="email"
                    id="clinician_email"
                    onChange={(e) => (clinicianData.email = e.target.value)}
                    className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  />
                  <p
                    id="emailnote"
                    className={
                      !validEmail ? 'instructions failed' : 'offscreen'
                    }
                  >
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    This email already exists, please enter a different one.
                  </p>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="mb-3 w-1/2 mr-2">
                <label
                  htmlFor="clinician_number"
                  className="block text-xs font-medium text-gray-600"
                >
                  Clinician Phone Number{' '}
                  <span className="text-blue-500 text-xs">
                    (Please use +44 and no spaces)
                  </span>
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    defaultValue={clinicianData.phone_number}
                    type="text"
                    required
                    value={phoneNumber}
                    onChange={(e) => {
                      handlePhoneNumberChange(e)
                      clinicianData.phone_number = e.target.value
                    }}
                    name="clinician_number"
                    id="clinician_number"
                    className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  />
                  {phoneNumber.length > 0 && (
                    <>
                      {isValid ? (
                        <span className="text-green-500 text-sm">
                          Valid phone number
                        </span>
                      ) : (
                        <span className="text-red-600 text-sm">
                          Invalid phone number
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="w-1/2 ml-2"></div>
            </div>
          </div>

          <hr className="bg-gray-300 w-full my-4" />

          <div>
            <h3 className="text-md font-bold mb-2">Clinic Info</h3>
            <div className="flex">
              <div className="mb-3 w-1/2 mr-2">
                <label
                  htmlFor="clinic_name"
                  className="block text-xs font-medium text-gray-600"
                >
                  Clinic Name
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    defaultValue={clinicData.name}
                    type="text"
                    name="clinic_name"
                    required
                    id="clinic_name"
                    onChange={(e) => (clinicData.name = e.target.value)}
                    className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  />
                </div>
              </div>
              <div className="mb-3 w-1/2 ml-2">
                <label
                  htmlFor="clinic_address"
                  className="block text-xs font-medium text-gray-600"
                >
                  Address
                </label>
                <div className="mt-1 relative rounded-md">
                  <input
                    ref={ref}
                    defaultValue={clinicData.address}
                    type="text"
                    name="clinic_address"
                    required
                    id="clinic_address"
                    className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="bg-gray-300 w-full my-4" />

          <div>
            <h3 className="text-md font-bold mb-2">Main Contact Details</h3>
            <div className="flex">
              <div className="mb-3 w-1/2 mr-2 flex items-center">
                <div className="relative mr-2">
                  <input
                    type="checkbox"
                    id="clinic_details"
                    onChange={(e) => {
                      setUseClinicianDetails(e.target.checked)
                      resetMainDetails()
                    }}
                    placeholder="Use Clinician Details"
                    className="field"
                    checked={useClinicianDetails}
                  />
                </div>
                <label
                  htmlFor="clinic_details"
                  className="block text-sm font-medium text-gray-600"
                >
                  Use Clinician details for main clinic contact details?
                </label>
              </div>
              <div className="mb-3 w-1/2 ml-2"></div>
            </div>
            {!useClinicianDetails && (
              <div>
                <div className="flex">
                  <div className="mb-3 w-1/2 mr-2">
                    <label
                      htmlFor="clinic_name"
                      className="block text-xs font-medium text-gray-600"
                    >
                      Main Contact Name
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clinicData.main_name}
                        type="text"
                        name="clinic_name"
                        id="clinic_name"
                        required
                        onChange={(e) =>
                          (clinicData.main_name = e.target.value)
                        }
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                  <div className="mb-3 w-1/2 ml-2">
                    <label
                      htmlFor="main_clinic_email"
                      className="block text-xs font-medium text-gray-600 text-sm"
                    >
                      Main Contact Email
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clinicData.main_email}
                        type="email"
                        required
                        name="main_clinic_email"
                        id="main_clinic_email"
                        onChange={(e) =>
                          (clinicData.main_email = e.target.value)
                        }
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="mb-3 w-1/2 mr-2">
                    <label
                      htmlFor="main_clinic_number"
                      className="block text-xs font-medium text-gray-600 text-sm"
                    >
                      Main Contact Number
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clinicData.main_number}
                        type="number"
                        required
                        name="main_clinic_number"
                        id="main_clinic_number"
                        onChange={(e) =>
                          (clinicData.main_number = e.target.value)
                        }
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                  <div className="mb-3 w-1/2 ml-2"></div>
                </div>
              </div>
            )}
          </div>

          <div className="flex mt-2">
            <button type="submit" className="btn">
              Create Clinic
            </button>
          </div>
        </form>
      </section>
    </section>
  )
}

export default CreateClinic
