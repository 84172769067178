import MainTitle from '../../components/MainTitle'
import Payslip from '../../components/Payslip'

import { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

function AccountPayslips() {
  const [practitioners, setPractitioners] = useState([])

  const { setShowLoader } = useAuth()
  const axios = useAxiosPrivate()

  const getPractitioners = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/users/all`)
      await setPractitioners(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getPractitioners()
  }, [])

  return (
    <section>
      <MainTitle title="Payslips" />

      <Payslip practitioners={practitioners} />
    </section>
  )
}

export default AccountPayslips
