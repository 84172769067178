import { useState, useEffect } from 'react'
import MainTitle from '../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClinicNav from '../../components/ClinicNav'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Payslip from '../../components/Payslip'

const Payslips = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()

  const [clinicData, setClinicData] = useState({ name: '' })
  const { setShowLoader } = useAuth()
  const [practitioners, setPractitioners] = useState([])

  const getClinicById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/companies/${id}`)
      await setClinicData(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getClinicById(id)
  }, [])

  const getPractitioners = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/companies/practitioners/${id}`)
      await setPractitioners(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getPractitioners()
  }, [])

  return (
    <section>
      <MainTitle title={`Clinic: ${clinicData.name}`} backButton />
      <ClinicNav active="payslips" clinicId={clinicData.id} />

      <Payslip practitioners={practitioners} />
    </section>
  )
}

export default Payslips
