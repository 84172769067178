import moment from 'moment'
import { useState, useEffect } from 'react'
import Pagination from '../components/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

function PayslipSessions({ data = [] }) {
  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  useEffect(() => {
    if (data) {
      setFilteredRecords(data)
      setCurrentRecords(
        data.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(data.length / recordsPerPage))
    }
  }, [data])

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }


  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  return (
    <div className="w-full py-4">
      <span className="text-lg font-semibold">Sessions</span>
      <div className="overflow-hidden rounded-lg mt-4">
        <table className="table-main">
          <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
            <tr>
              <th scope="col" className="px-6 py-4">
                Date
              </th>
              <th scope="col" className="px-6 py-4">
                Service
              </th>
              <th scope="col" className="px-6 py-4">
                Patient
              </th>
              <th scope="col" className="px-6 py-4">
                Duration (mins)
              </th>
              <th scope="col" className="px-6 py-4">
                Amount
              </th>
              <th scope="col" className="px-6 py-4">
                Notes
              </th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 ? (
              currentRecords.map((item, index) => {
                const start = moment(item.start_time)
                const end = moment(item.end_time)
                const duration = moment
                  .duration(end.diff(start))
                  .asMinutes()
                  .toFixed(2)
                return (
                  <tr
                    key={index}
                    className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                  >
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {moment(item.start_time).format('DD/MM/YY HH:mm')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.service.title}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.client.reference}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {duration}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.premium_cost.cost}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.premium_cost.title}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <p className="no-records">No Sessions found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          next={nextPage}
          prev={previousPage}
          first={indexOfFirstRecord}
          last={indexOfLastRecord}
          total={filteredRecords.length}
        />
      </div>
    </div>
  )
}

export default PayslipSessions
