import { useState, useEffect } from 'react'
import moment from 'moment'
import Datepicker from 'react-tailwindcss-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../img/swb-logo.png'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

import {
  faCalendar,
  faClock,
  faCoins,
  faDownload,
  faEnvelope,
  faFileInvoiceDollar,
  faPlusMinus,
} from '@fortawesome/free-solid-svg-icons'
import BulkPayments from './BulkPayments'
import PayslipSessions from './PayslipSessions'
import PayslipAdjustments from './PayslipAdjustments'
import PayslipHistory from './PayslipHistory'

const Payslip = ({ practitioners }) => {
  const { setShowLoader, can } = useAuth()
  const axios = useAxiosPrivate()

  const [selectedPractitioner, setSelectedPractitioner] = useState(null)
  const [value, setValue] = useState({
    startDate: moment(),
    endDate: moment(),
  })
  const [toggle, setToggle] = useState(true)
  const [togglePayments, setTogglePayments] = useState(false)
  const [adjustmentData, setAdjustmentData] = useState([])
  const [payslipData, setPayslipData] = useState([])
  const [totalAdjustments, setTotalAdjustments] = useState(0)
  const [totalSessions, setTotalSessions] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0)
  const [payslipHistory, setPayslipHistory] = useState([])
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(true)
  const [combinedData, setCombinedData] = useState([])

  const getTotalAdjustments = (data) => {
    let totalAdjustment = 0
    data.forEach((item) => {
      totalAdjustment += Number(item.amount)
    })

    setTotalAdjustments(totalAdjustment)
  }

  const getTotalSessions = (data) => {
    let totalCost = 0
    data.forEach((item) => {
      totalCost += Number(item.premium_cost.cost)
    })

    setTotalSessions(totalCost)
  }

  const handleClearFilters = () => {
    setToggle(true)
    setTogglePayments(false)
    setSelectedPractitioner(null)
    setValue({ startDate: new Date(), endDate: new Date() })
    setPayslipHistory([])
    setPayslipData([])
    setAdjustmentData([])
    setCombinedData([])
  }

  const getTotalDuration = (data) => {
    let totalDuration = 0
    data.forEach((item) => {
      const start = moment(item.start_time)
      const end = moment(item.end_time)
      totalDuration += moment.duration(end.diff(start)).asHours()
    })
    setTotalDuration(totalDuration.toFixed(2))
  }

  const getPayslipData = async (id, start, end) => {
    try {
      setShowLoader(true)
      setIsActionButtonDisabled(true)
      const response = await axios.get(
        `/payslips/user/${id}/${moment(start).format('YYYY-MM-DD')}/${moment(
          end
        ).format('YYYY-MM-DD')}`
      )
      await setPayslipData(response.data.result)
      getTotalSessions(response.data.result)
      await getTotalDuration(response.data.result)
      setIsActionButtonDisabled(false)
      setShowLoader(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getAdjustmentData = async (id, start, end) => {
    try {
      const response = await axios.get(
        `/adjustments/${id}/${moment(start).format('YYYY-MM-DD')}/${moment(
          end
        ).format('YYYY-MM-DD')}`
      )
      await setAdjustmentData(response.data.result)
      getTotalAdjustments(response.data.result)
    } catch (error) {}
  }

  const getPayslipHistory = async (id) => {
    try {
      const response = await axios.get(`/users/${id}/payslips`)
      await setPayslipHistory(response.data.result)
    } catch (error) {}
  }

  const downloadPayslip = async () => {
    const data = {
      practitioner: selectedPractitioner,
      sessions: payslipData,
      adjustments: adjustmentData,
      adjustmentTotal: totalAdjustments,
      totalSessions: totalSessions,
      totalDuration: totalDuration,
      dates: value,
    }

    try {
      setShowLoader(true)
      const response = await axios.post(`/payslips/download`, data)

      if (!response.data.error) {
        window
          .open(
            process.env.REACT_APP_URL +
              '/payslip/download/' +
              response.data.result,
            '_blank'
          )
          .focus()
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const emailPayslip = async () => {
    const data = {
      practitioner: selectedPractitioner,
      sessions: payslipData,
      adjustments: adjustmentData,
      adjustmentTotal: totalAdjustments,
      totalSessions: totalSessions,
      totalDuration: totalDuration,
      dates: value,
    }

    try {
      setShowLoader(true)
      const response = await axios.post(`/payslips/email-payslip`, data)
    } catch {}
    setShowLoader(false)
  }

  const handleBulkPayments = () => {
    if (togglePayments === true) {
      setTogglePayments(false)
    } else {
      setTogglePayments(true)
    }
  }

  const handleValueChange = (newValue) => {
    setValue(newValue)

    if (selectedPractitioner !== null) {
      getAdjustmentData(
        JSON.parse(selectedPractitioner).id,
        newValue.startDate,
        newValue.endDate
      )
      getPayslipData(
        JSON.parse(selectedPractitioner).id,
        newValue.startDate,
        newValue.endDate
      )
    } else {
      getAdjustmentData(0, newValue.startDate, newValue.endDate)
      getPayslipData(0, newValue.startDate, newValue.endDate)
      getPayslipHistory(0)
    }
  }

  useEffect(() => {
    const combined = {}
    payslipData.forEach((item) => {
      let duration = []
      const startDate = moment(item.start_time)
      const endDate = moment(item.end_time)
      duration += moment.duration(endDate.diff(startDate)).asHours()

      if (!combined[item.user_id]) {
        combined[item.user_id] = {
          id: item.user_id,
          name: item.user?.name,
          email: item.user?.email,
          totalDuration: 0,
          totalSessions: 0,
          adjustmentTotal: 0,
          sessions: [],
          adjustments: [],
          dates: [value.startDate, value.endDate],
        }
      }
      combined[item.user_id].totalDuration += parseFloat(duration)
      combined[item.user_id].totalSessions += parseFloat(item.premium_cost.cost)
      combined[item.user_id].sessions.push(item)
    })

    adjustmentData.forEach((item) => {
      if (!combined[item.user_id]) {
        combined[item.user_id] = {
          id: item.user_id,
          name: item.user?.name,
          email: item.user?.email,
          totalDuration: 0,
          totalSessions: 0,
          adjustmentTotal: 0,
          sessions: [],
          adjustments: [],
          dates: [],
        }
      }
      combined[item.user_id].adjustmentTotal += parseFloat(item.amount)
      combined[item.user_id].adjustments.push(item)
    })

    setCombinedData(Object.values(combined))
  }, [payslipData, adjustmentData])

  return (
    <>
      <section className="space-y-6 mx-auto">
        <div className="w-full rounded-lg bg-white p-6">
          <div className="flex items-center justify-between space-x-6">
            <div className="flex items-center justify-start w-2/3 space-x-8">
              <div className="w-full">
                <label
                  htmlFor="practitioners"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Create a payslip
                </label>
                <select
                  disabled={togglePayments === true}
                  onChange={async (e) => {
                    setSelectedPractitioner(e.target.value)
                    setIsActionButtonDisabled(true)
                    const practitionerId = JSON.parse(e.target.value).id
                    await getPayslipData(
                      practitionerId,
                      value.startDate,
                      value.endDate
                    )
                    await getAdjustmentData(
                      practitionerId,
                      value.startDate,
                      value.endDate
                    )
                    getPayslipHistory(practitionerId)
                    setIsActionButtonDisabled(false)
                  }}
                  value={selectedPractitioner || 0}
                  id="practitioners"
                  className="border border-gray-200 text-gray-900 text-sm rounded-lg focus:ring-blue-400 focus:border-blue-400 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option disabled value={0}>
                    Choose a Practitioner
                  </option>
                  {practitioners.length > 0 &&
                    practitioners?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      )
                    })}
                </select>
              </div>
              <div className="w-full">
                <label className="invisible">date</label>
                <div className=" border border-gray-200 rounded-lg p-0.5">
                  <Datepicker
                    primaryColor={'blue'}
                    value={value}
                    onChange={handleValueChange}
                    useRange={false}
                    displayFormat={'DD/MM/YYYY'}
                    separator="-"
                    startWeekOn="mon"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2 mt-5">
              <div onClick={handleClearFilters}>
                <button className="rounded-lg bg-wba-primary text-white px-4 py-2 hover:bg-wba-primary-hover text-base">
                  Clear Filters
                </button>
              </div>
              {selectedPractitioner ? (
                <button
                  onClick={() => setToggle(!toggle)}
                  className="rounded-lg bg-blue-500 text-white px-4 py-2 hover:bg-blue-700 text-base"
                >
                  {toggle ? 'Preview Payslip' : 'Close Preview'}
                </button>
              ) : (
                <>
                  {can('bulk pay') && (
                    <div onClick={handleBulkPayments}>
                      <button className="rounded-lg bg-blue-500 text-white px-4 py-2 hover:bg-blue-700 text-base">
                        {togglePayments ? 'Close View ' : 'Bulk Payments'}{' '}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {togglePayments ? (
          <div>
            {/* {selectedPractitioner == null && (<BulkPayments data={combinedData} />)} */}
            <BulkPayments data={combinedData} />
          </div>
        ) : (
          <>
            {!toggle ? (
              <div className="p-6 max-w-5xl mx-auto">
                <div className=" flex items-center justify-end">
                  <button
                    onClick={downloadPayslip}
                    disabled={isActionButtonDisabled}
                    className={`flex items-center rounded-md px-4 py-2 mr-2 mb-4 ${
                      isActionButtonDisabled
                        ? 'invisible'
                        : 'visible bg-wba-primary hover:bg-wba-primary-hover text-white'
                    }`}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                    <span className="ml-2">Download</span>
                  </button>
                  <button
                    onClick={emailPayslip}
                    disabled={isActionButtonDisabled}
                    className={`rounded-md px-4 py-2 mb-4 ${
                      isActionButtonDisabled
                        ? 'invisible'
                        : 'visible bg-slate-500 hover:bg-blue-700 text-white'
                    }`}
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span className="ml-2">Email</span>
                  </button>
                </div>
                <div className="bg-white rounded-lg p-6 max-w-5xl mx-auto">
                  <div className="flex justify-between mt-4">
                    <div>
                      <div className="text-xl pb-1">
                        {JSON.parse(selectedPractitioner)?.name}
                      </div>
                      {/* <div className="text-sm pb-4">42 Walloby Way, Sydney</div> */}
                    </div>
                    <img
                      src={logo}
                      alt="School Wellbeing Logo"
                      className="w-72"
                    />
                  </div>
                  <div>
                    <div className="text-xl pb-1">Payslip</div>
                    <div className="text-sm">
                      {moment().format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div className="my-6">
                    <div className="flex text-start items-start justify-between mb-4">
                      <div>
                        <div className="font-semibold">Payslip For</div>
                        <div>
                          {' '}
                          Make A Difference (UK) Limited <br /> 44 Tyndall Court{' '}
                          <br /> Peterborough <br /> PE2 6LR{' '}
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold">Covering</div>
                        <div>
                          {moment(value.startDate).format('DD/MM/YYYY')} -{' '}
                          {moment(value.endDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                      <div>
                        <div className="font-semibold">Payslip ID</div>
                        <div>
                          {JSON.parse(selectedPractitioner)?.name.replace(
                            / /g,
                            ''
                          ) ?? 0}
                          -{moment(value.startDate).format('MM-YYYY')}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="mt-8 font-semibold text-lg">Sessions</div>
                    <div className="inline-block min-w-full py-2">
                      <div className="overflow-hidden">
                        <table className="min-w-full text-left text-sm font-light">
                          <thead className="border-b font-medium dark:border-neutral-500">
                            <tr>
                              <th scope="col" className="px-6 py-4">
                                Date
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Service
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Patient
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Duration (mins)
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Amount
                              </th>
                              <th scope="col" className="px-6 py-4">
                                Notes
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {payslipData.length > 0 &&
                              payslipData.map((item, index) => {
                                const start = moment(item.start_time)
                                const end = moment(item.end_time)
                                const duration = moment
                                  .duration(end.diff(start))
                                  .asMinutes()
                                  .toFixed(2)
                                return (
                                  <tr
                                    key={index}
                                    className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
                                  >
                                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                                      {moment(item.start_time).format(
                                        'DD/MM/YY HH:mm'
                                      )}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {item.service.title}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {item.client.reference}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {duration}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      &pound;{item.premium_cost.cost}
                                    </td>
                                    <td className="whitespace-nowrap px-6 py-4">
                                      {item.premium_cost.title}
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="my-8">
                      <div className="mt-8 font-semibold text-lg">
                        Adjustments
                      </div>
                      <div className="inline-block min-w-full py-2">
                        <div className="overflow-hidden">
                          <table className="min-w-full text-left text-sm font-light">
                            <thead className="border-b font-medium dark:border-neutral-500">
                              <tr>
                                <th scope="col" className="px-6 py-4">
                                  Date
                                </th>
                                <th scope="col" className="px-6 py-4">
                                  Reason
                                </th>
                                <th scope="col" className="px-6 py-4">
                                  Adjustment ID
                                </th>
                                <th scope="col" className="px-6 py-4">
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {adjustmentData.length > 0 &&
                                adjustmentData.map((item, index) => {
                                  const start = moment(item.start_time)
                                  const end = moment(item.end_time)
                                  const duration = moment
                                    .duration(end.diff(start))
                                    .asMinutes()
                                    .toFixed(2)
                                  return (
                                    <tr
                                      key={index}
                                      className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-600"
                                    >
                                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                                        {moment(item.date).format('DD/MM/YY')}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-4">
                                        {item.reason}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-4">
                                        {item.id}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-4">
                                        {item.amount}
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="mt-8 font-semibold text-lg">Summary</div>
                      <div className="flex justify-end">
                        <div className="w-1/3">
                          <div className="flex items-center justify-between">
                            <div className="font-semibold">Hours Worked</div>{' '}
                            <div>{totalDuration}</div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="font-semibold">Adjustments</div>{' '}
                            <div>£{totalAdjustments}</div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="font-semibold">Sessions</div>{' '}
                            <div>£{totalSessions}</div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="font-semibold">Payslip Total</div>{' '}
                            <div>£{totalAdjustments + totalSessions}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="w-full py-4">
                  <span className="text-lg font-semibold">Payslip Summary</span>
                  <div className="grid grid-cols-3 gap-4 pt-4">
                    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 border border-slate-100">
                      <dt>
                        <div className="absolute rounded-md bg-blue-500 p-3">
                          <FontAwesomeIcon
                            icon={faPlusMinus}
                            className="px-1 text-white w-6 h-6"
                          />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                          Adjustments
                        </p>
                      </dt>
                      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                          £{totalAdjustments}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600"></p>
                      </dd>
                    </div>
                    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 border border-slate-100">
                      <dt>
                        <div className="absolute rounded-md bg-blue-500 p-3">
                          <FontAwesomeIcon
                            icon={faCoins}
                            className="px-1 text-white w-6 h-6"
                          />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                          Sessions
                        </p>
                      </dt>
                      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                          £{totalSessions}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600"></p>
                      </dd>
                    </div>
                    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 border border-slate-100">
                      <dt>
                        <div className="absolute rounded-md bg-blue-500 p-3">
                          <FontAwesomeIcon
                            icon={faFileInvoiceDollar}
                            className="px-1 text-white w-6 h-6"
                          />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                          Payslip Total
                        </p>
                      </dt>
                      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                          £{totalAdjustments + totalSessions}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600"></p>
                      </dd>
                    </div>
                    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 border border-slate-100">
                      <dt>
                        <div className="absolute rounded-md bg-blue-500 p-3">
                          <FontAwesomeIcon
                            icon={faClock}
                            className="px-1 text-white w-6 h-6"
                          />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                          Total Duration
                        </p>
                      </dt>
                      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                          {totalDuration} hours
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600"></p>
                      </dd>
                    </div>{' '}
                    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6 border border-slate-100">
                      <dt>
                        <div className="absolute rounded-md bg-blue-500 p-3">
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="px-1 text-white w-6 h-6"
                          />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-gray-500">
                          Number of Sessions
                        </p>
                      </dt>
                      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900">
                          {payslipData.length}
                        </p>
                        <p className="ml-2 flex items-baseline text-sm font-semibold text-green-600"></p>
                      </dd>
                    </div>
                  </div>
                </div>
                <PayslipSessions data={payslipData} />
                <PayslipAdjustments
                  data={adjustmentData}
                  selectedPractitioner={JSON.parse(selectedPractitioner)}
                  setAdjustmentData={setAdjustmentData}
                />
                <PayslipHistory data={payslipHistory} />
              </>
            )}
          </>
        )}
      </section>
    </>
  )
}

export default Payslip
