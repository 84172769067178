import moment from 'moment'
import { useState, useEffect } from 'react'
import Pagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function BulkPayments({ data = [] }) {
  const { setShowLoader, createInfo } = useAuth()
  const axios = useAxiosPrivate()
  const [payslipData, setPayslipData] = useState([])

  const [nPages, setNumberOfPages] = useState(0)
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    if (data) {
      setPayslipData(
        data
        // data.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      // setNumberOfPages(Math.ceil(data.length / recordsPerPage))
    }
  }, [data])

  // Handle row checkbox change
  const handleRowCheckboxChange = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
    } else {
      setSelectedRows([...selectedRows, id])
    }
  }

  // Handle header checkbox change
  const handleHeaderCheckboxChange = () => {
    if (selectedRows.length === payslipData.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(payslipData.map((item) => item.id))
    }
  }

  // Handle email button click
  const handleEmailButtonClick = async () => {
    setShowLoader(true)
    const selectedData = payslipData.filter((item) =>
      selectedRows.includes(item.id)
    )
    const timeOut = setTimeout(() => {
      createInfo('info', 'Practitioner payslips processing...')
      setSelectedRows([])
      setShowLoader(false)
    }, 2500)
    try {
      await axios.post('/payslips/email-payslip', selectedData).then((res) => {
        console.log(res)
      })
    } catch (error) {
      console.error('Error sending emails:', error)
      timeOut.clearTimeout()
      createInfo('error', 'There was an error sending the payslip emails')
    }
    setShowLoader(false)
  }

  return (
    <div className="w-full py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold">Bulk Payments Overview</div>
        <div className="flex items-center justify-end space-x-2">
          {selectedRows.length > 0 && (
            <button
              onClick={handleEmailButtonClick}
              className="rounded-lg bg-wba-primary text-white px-4 py-2 hover:bg-wba-primary-hover text-base"
            >
              <FontAwesomeIcon icon={faPaperPlane} className="pr-2" />
              Send Payslips
            </button>
          )}
        </div>
      </div>
      <div className="overflow-hidden rounded-lg mt-4">
        <table className="table-main">
          <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
            <tr>
              <th scope="col" className="px-6 py-4">
                <input
                  type="checkbox"
                  checked={selectedRows.length === payslipData.length}
                  onChange={handleHeaderCheckboxChange}
                  className=""
                />
              </th>
              <th scope="col" className="px-6 py-4">
                Counsellor Name
              </th>
              <th scope="col" className="px-6 py-4">
                Hours Worked
              </th>
              <th scope="col" className="px-6 py-4">
                Adjustments
              </th>
              <th scope="col" className="px-6 py-4">
                Sessions (total in £)
              </th>
              <th scope="col" className="px-6 py-4">
                Total (sessions + adjustments)
              </th>
            </tr>
          </thead>
          <tbody>
            {payslipData.length > 0 ? (
              payslipData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                  >
                    <td className="px-6 py-4 w-10">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={() => handleRowCheckboxChange(item.id)}
                        className=""
                      />
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.name}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {item.totalDuration}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      £{item.adjustmentTotal}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      £{item.totalSessions}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      £{(item.adjustmentTotal + item.totalSessions).toFixed(2)}
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <p className="no-records">No Sessions found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BulkPayments
