import useAuth from '../hooks/useAuth'
import { useEffect, useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Modal from './Modal';

function ActiveQuestionnaire({
  questionnaireData,
  clientReference,
  downloadable,
  resetQ,
  session,
  setList,
  list,
  update,
}) {
  const [questionnaireResponse, setQuestionnaireResponse] = useState({})
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [isFormSave, setIsFormSave] = useState(false)
  const { setShowLoader, createInfo } = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const axios = useAxiosPrivate()

  useEffect(() => {
    setQuestionnaireResponse(questionnaireData.content)
  }, [questionnaireData])

  const cleanupPDF = async (url) => {
    try {
      const response = await axios.post(`/pdf/cleanup`, { url: url })
      if (response.data.error) {
        console.log(response.data.error.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleMouseLeave = () => {
    if (isFormSave === true) {
     unsavedChangesClicked()
    }
  };

  const GenerateQuestion = ({ data, index, setIsFormSave }) => {

    const handleChange = (e, value) => {
      questionnaireResponse.questions[index].answerGiven = e.target.value
      setIsFormSave(true)
    }

    const question = {
      index,
      type: data.type.type,
      label: data.question,
      description: data.description,
      rangeOptions: data.options,
      options: convertQuestionOptionsToSelectBoxOptions(
        data.options,
        data.optionValues
      ),
      required: data.required,
      min: parseInt(data.min, 10),
      max: parseInt(data.max, 10),
      maxRows: 30,
      optionsCount: parseInt(data.optionsCount, 10),
      tally: false,
    }

    switch (data.type.type) {
      case 'text':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="text"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'date':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="date"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'time':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="time"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'number':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="number"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'email':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="email"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'telephone':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="tel"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'text-area':
        question.component = (
          <div>
            <textarea
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              type="text"
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'range':
        question.component = (
          <div>
            <input
              required={question.required}
              name={`question_${index}`}
              id={questionnaireResponse[index]}
              onChange={(e) => handleChange(e, e.target.value)}
              type="range"
              defaultValue={questionnaireResponse.questions[index].answerGiven}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        )
        break
      case 'checkbox':
        if (questionnaireResponse.questions[index].answerGiven == null) {
          questionnaireResponse.questions[index].answerGiven = []
        }
        if (questionnaireResponse.questions[index].answerGiven.length <= 0) {
          questionnaireResponse.questions[index].answerGiven = []
        }
        question.component = question?.options.map((option, checkIndex) => {
          return (
            <div className="items-center flex">
              <input
                type="checkbox"
                id={`${index}_check_${checkIndex}`}
                value={option.value}
                name={`question_${index}`}
                defaultChecked={
                  questionnaireResponse.questions[index].answerGiven[checkIndex]
                }
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                onChange={(e) => { 
                  handleChange(e, e.target.value)   
                  updateTotal()
                }}
              />
              <label
                htmlFor={`${index}_check_${checkIndex}`}
                className="ml-2 mr-4 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {option.text}
              </label>
            </div>
          )
        })
        break
      case 'radio':
        question.component = question?.options.map((option, radioIndex) => {
          return (
            <div className="items-center flex">
              <input
                type="radio"
                required={question.required}
                id={`${index}_radio_${radioIndex}`}
                name={`question_${index}`}
                value={option.value}
                defaultChecked={
                  questionnaireResponse.questions[index].answerGiven ===
                  option.value
                }
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                onChange={(e) => handleChange(e, e.target.value)}
              />
              <label
                htmlFor={`${index}_radio_${radioIndex}`}
                className="ml-2 mr-4 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                {option.text}
              </label>
            </div>
          )
        })
        break
      case 'select':
        question.component = (
          <div>
            <select
              defaultValue={
                questionnaireResponse.questions[index].answerGiven ?? ''
              }
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required={question.required}
              onChange={(e) => { 
                handleChange(e, e.target.value)   
                updateTotal()
              }}
            >
              <option disabled value={''}>
                Select an Option
              </option>
              {question?.options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.text}
                  </option>
                )
              })}
            </select>
          </div>
        )
        break
      default:
        break
    }
    return (
      <div className="mb-3">
        <h2 className="font-medium mb-1">{question.label}</h2>
        {question.component}
        <p className="text-sm mt-1 font-gray-500">{question.description}</p>
      </div>
    )
  }

  const saveQuestionnaire = async (e) => {
    setShowLoader(true)
    setIsFormSave(false)
    e.preventDefault()
    const data = {
      session_id: session.id,
      content: questionnaireResponse,
    }

    let url = 'store'
    if (update === true) {
      url = `update/${questionnaireData.id}`
    }

    try {
      const response = await axios.post(`/questionnaire-response/${url}`, data)
      if (!response.data.error) {
        if (!update) {
          setList([...list, response.data.result])
        } else {
          setList(response.data.result)
        }
        createInfo(
          'success',
          `Questionnaire '${questionnaireResponse.title}' saved`
        )
        resetQ()
      }
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const downloadQuestionnaire = async () => {
    try {
      setShowLoader(true)

      const response = await axios
        .post(`/pdf/download`, {
          id: questionnaireData.id,
          clientReference: clientReference,
        })
        .then((response) => {
          window.open(response.data)
          setShowLoader(false)
          cleanupPDF(response.data)
        })
    } catch (error) {
      console.log(error)
      // Error handling
    }
  }

  const updateTotal = () => {
    const total = questionnaireResponse.questions.reduce((acc, question) => {
      const answer = question.answerGiven
      const answerIndex = Object.values(question.options).indexOf(answer)
      const answerValue = Object.values(question.optionValues)[answerIndex]
      // if (!question.tally) {
      //   return acc
      // }

      if (typeof answerValue === 'string') {
        acc += parseInt(answerValue, 10) || 0
      } else if (typeof answerValue === 'number') {
        acc += answerValue
      } else if (Array.isArray(answerValue)) {
        acc += answerValue.reduce((subAcc, cur) => {
          subAcc += parseInt(cur, 10)
          return subAcc
        }, 0)
      }

      return acc
    }, 0)
    setQuestionnaireResponse({ ...questionnaireResponse, total: total })
  }

  const convertQuestionOptionsToSelectBoxOptions = (options) => {
    return Object.keys(options).map((key) => ({
      value: options[key],
      text: options[key],
    }))
  }

  const unsavedChangesClicked = () => {
    setModalTitle(`You have unsaved changes`)
    setUnsavedChanges(true)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (unsavedChanges) {
      return <UnsavedElement />
    }
  }


  const UnsavedElement = () => {
    const saveChanges = async (e) => {
      saveQuestionnaire(e)
    };
  
    return (
      <form onSubmit={saveChanges}>
        <div className="mb-3">
          You have unsaved changes. Please save your changes before leaving the page.
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={() => {
              resetModal();
            }}>
            No
          </button>
        </div>
      </form>
    );
  };


  const resetModal = () => {
    setShowModal(false)
  }

  return (
    <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <form onSubmit={saveQuestionnaire} onMouseLeave={handleMouseLeave}>
        <div className="flex justify-between">
          <div className="w-1/2">
            <h3 className="font-bold text-lg mb-2">
              {questionnaireResponse?.title}
            </h3>
          </div>
          <div className="w-1/2 flex justify-end">
            <button className="btn green mr-4">Save Questionnaire</button>
            {downloadable && (
              <button
                className="btn blue mr-4"
                type="button"
                onClick={() => {
                  downloadQuestionnaire()
                }}
              >
                Download Questionnaire
              </button>
            )}
            <button
              className="btn gray"
              onClick={(e) => {
                e.preventDefault()
                resetQ()
              }}
            >
              Close
            </button>
          </div>
        </div>
        <div>
          {questionnaireResponse?.questions?.map((item, index) => {
            return <GenerateQuestion data={item} index={index} setIsFormSave={setIsFormSave} />
          })}
        </div>
        <div className="w-full flex justify-between">
          <h2>Total: {questionnaireResponse.total}</h2>
          <div className="flex justify-end">
            <button className="btn green mr-4">Save Questionnaire</button>
            {downloadable && (
              <button
                className="btn blue mr-4"
                type="button"
                onClick={() => {
                  downloadQuestionnaire()
                }}
              >
                Download Questionnaire
              </button>
            )}
            <button
              className="btn gray"
              onClick={(e) => {
                e.preventDefault()
                resetQ()
              }}
            >
              Close
            </button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default ActiveQuestionnaire
