import { Link } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function ClinicNav({ active = 'dashboard', clinicId }) {
  const { can } = useAuth()

  return (
    <div className="bg-white rounded-md shadow-md py-3 px-4 mb-8">
      <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
        <Link to={`/clinics/${clinicId}`}>
          <li className={`tab-btn ${active === 'dashboard' ? 'active' : ''}`}>
            Dashboard
          </li>
        </Link>
        {can('view payslip') && (
          <Link to={`/clinics/${clinicId}/payslips`}>
            <li className={`tab-btn ${active === 'payslips' ? 'active' : ''}`}>
              Payslips
            </li>
          </Link>
        )}
        {can('view clinic availability') && (
          <Link to={`/clinics/${clinicId}/availability`}>
            <li
              className={`tab-btn ${active === 'availability' ? 'active' : ''}`}
            >
              All Availability
            </li>
          </Link>
        )}
        {can('view clinic bookings') && (
          <Link to={`/clinics/${clinicId}/bookings`}>
            <li className={`tab-btn ${active === 'bookings' ? 'active' : ''}`}>
              All Bookings
            </li>
          </Link>
        )}
        {/* {can('view clinic settings') && (
          <Link to={`/clinics/${clinicId}/settings`}>
            <li className={`tab-btn ${active === 'settings' ? 'active' : ''}`}>
              Settings
            </li>
          </Link>
        )} */}
      </ul>
    </div>
  )
}

export default ClinicNav
