/* eslint-disable */
import MainTitle from '../../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../../components/CardButton'
import useAuth from '../../../hooks/useAuth'

import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Modal from '../../../components/Modal'
import moment from 'moment'
import NotificationItem from '../../../components/NotificationItem'

function Notifications() {
  const axios = useAxiosPrivate()
  const { setShowLoader, can, createInfo } = useAuth()

  const [notifications, setNotifications] = useState([])
  const [deleteNotification, setDeleteNotification] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [editNotification, setEditNotification] = useState({})
  const [notificationTypes, setNotificationTypes] = useState({})
  const [createNotification, setCreateNotification] = useState({})
  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getNotifications(), getNotificationTypes()])
  }

  const getNotifications = async () => {
    try {
      const response = await axios.get('/notifications/all')
      setNotifications(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  const getNotificationTypes = async (e) => {
    const response = await axios.get('/notification-types/all')
    await setNotificationTypes(response.data.result)
  }

  const resetModal = (resetNotifications = true) => {
    setDeleteNotification({})
    setCreateNotification({})
    setEditNotification({})
    setShowModal(false)
    if (resetNotifications) {
      setNotifications(notifications)
    }
  }

  const ModalBody = () => {
    if (deleteNotification.id) {
      return <DeleteNotificationElement />
    }

    if (createNotification) {
      return <CreateNotificationElement />
    }
  }

  const CreateNotificationElement = () => {
    const addNotification = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          '/notifications/create',
          createNotification
        )
        createInfo(
          'success',
          `Created Notification: ${createNotification.title}`
        )
        setNotifications([...notifications, response.data.result])
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }

    return (
      <form onSubmit={addNotification}>
        <div className="mb-3">
          <label
            htmlFor="title"
            className="block font-medium text-gray-600 text-sm"
          >
            Title
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createNotification.title}
              required
              type="text"
              name="name"
              id="name"
              onChange={(e) => (createNotification.title = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="body"
            className="block font-medium text-gray-600 text-sm"
          >
            Body
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createNotification.body}
              type="text"
              required
              name="body"
              id="body"
              onChange={(e) => (createNotification.body = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="mt-1 relative rounded-md">
            <label
              htmlFor="notificationType"
              className="block font-medium text-gray-600 text-sm"
            >
              Notification Type
            </label>
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => (createNotification.type = e.target.value)}
            >
              <option disabled selected>
                Select Notification Type
              </option>
              {notificationTypes?.map((type) => {
                return (
                  <option key={type.title} value={type.title}>
                    {type.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create Notification</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const deleteNotificationClicked = (notification) => {
    setDeleteNotification({ ...notification })
    setModalTitle(`Delete Notification: ${notification.title}`)
    setShowModal(true)
  }

  const createNotificationClicked = () => {
    setCreateNotification({})
    setModalTitle(`Create Notification`)
    setShowModal(true)
  }

  const DeleteNotificationElement = () => {
    const deleteNotificationFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.get(
          '/notifications/delete/' + deleteNotification.id
        )
        createInfo('error', `Deleted Notification: ${deleteNotification.title}`)
        setNotifications(
          notifications.filter((notification) => {
            return notification.id !== deleteNotification.id
          })
        )
        setFilteredRecords(response.data.result)
        setCurrentRecords(
          response.data.result.slice(indexOfFirstRecord, indexOfLastRecord)
        )
        setNumberOfPages(
          Math.ceil(response.data.result.length / recordsPerPage)
        )

        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteNotificationFn}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this notification?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Delete notification
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <section>
      <MainTitle title="Notifications" backButton />
      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {/* {can("create notifications") && ( */}
          <li className="w-1/4 mb-6 mr-3">
            <div onClick={createNotificationClicked}>
              <CardButton icon={faPlus} title="Create Notification" />
            </div>
          </li>
          {/* )} */}
        </ul>
      </section>
      <section>
        <div className="flex flex-wrap">
          {notifications?.map((notification) => {
            return (
              <div key={notification.id} className="w-1/2 notification-parent">
                <NotificationItem
                  notification={notification}
                  deleteNotification={deleteNotificationClicked}
                  admin
                />
              </div>
            )
          })}
        </div>
      </section>
    </section>
  )
}

export default Notifications
