import { useState, useEffect } from 'react'
import MainTitle from '../../components/MainTitle'
import { PhoneNumberUtil } from 'google-libphonenumber'
// import { usePlacesWidget } from "react-google-autocomplete"
import AutoComplete from 'react-google-autocomplete'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Switch } from '@headlessui/react'

const GOOGLE_API_KEY = 'AIzaSyDcTd3xEx_Y4ZlRo-7eeoSWzLUnWO67LFU'

function CreateClient() {
  const axios = useAxiosPrivate()
  const { setShowLoader, createInfo } = useAuth()

  const navigate = useNavigate()

  const [validEmail, setValidEmail] = useState(true)
  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [clientData] = useState({})
  const [clientAddress, setClientAddress] = useState({})
  const [clientGpData] = useState({})
  const [gpAddress, setGPAddress] = useState({})
  const [emergencyContactAddress, setEmergencyContactAddress] = useState({})
  const [emergencyContact] = useState({})
  const [childDetails] = useState({})

  const [contactMethods, setContactMethods] = useState([])
  const [allowedContactTypes] = useState([])
  const [regions, setRegions] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [childPhoneNumber, setChildPhoneNumber] = useState('')
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [toggleChild, setToggleChild] = useState(false)
  const [enabled, setEnabled] = useState(true)

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target
    setPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const handleEmergencyPhoneNumberChange = (event) => {
    const { value } = event.target
    setEmergencyPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const handleChildPhoneNumberChange = (event) => {
    const { value } = event.target
    setChildPhoneNumber(value)
    const isValidNumber = validatePhoneNumber(value)
    setIsValid(isValidNumber)
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberUtil = PhoneNumberUtil.getInstance()

    try {
      const parsedNumber = phoneNumberUtil.parse(phoneNumber, '+44')
      const isValidNumber = phoneNumberUtil.isValidNumber(parsedNumber)
      return isValidNumber
    } catch (e) {
      console.error('Error parsing phone number:', e)
      return false
    }
  }

  const updateContactMethod = (method) => {
    let index = allowedContactTypes.indexOf(method.id)
    if (index === -1) {
      allowedContactTypes.push(method.id)
    } else {
      allowedContactTypes.splice(index, 1)
    }
  }

  const createClient = async (e) => {
    e.preventDefault()
    // setValidEmail(true)
    // setValidPhoneNumber(true)
    // setShowLoader(true)

    //validate inputs
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,}$/
    const emailValid = emailRegex.test(clientData.email)
    if (!emailValid) {
      createInfo('error', `Please enter a valid email address`)
      return
    }

    //Check user email
    try {
      const response = await axios.get(`/client/by-email/${clientData.email}`)
      if (response.data.error === true) {
        setShowLoader(false)
        setValidEmail(false)
        createInfo('error', `This email is already in use ${clientData.email}`)
        return
      }
    } catch (error) {}
    //check user phone number
    try {
      const response = await axios.get(
        `/client/by-number/${clientData.phone_number}`
      )
      if (response.data.error === true) {
        setShowLoader(false)
        setValidPhoneNumber(false)
        createInfo('error', `This mobile number is already in use`)
        return
      }
    } catch (error) {}

    const data = {
      client: {
        name: clientData.name,
        email: clientData.email,
        child: toggleChild,
        status_id: 1,
      },
      client_details: {
        home_address: clientData.address,
        phone_number: clientData.phone_number.replace(/\s/g, ''),
        date_of_birth: clientData.date_of_birth,
        region_id: 0,
        contact_time: clientData.contact_time,
        send_notifications: enabled,
        emergency_contact: JSON.stringify({
          name: emergencyContact.name,
          contact_number: emergencyContact.contact_number,
          address: emergencyContact.address,
          relationship: emergencyContact.relationship,
        }),
        gp_details: JSON.stringify({
          name: clientGpData.name,
          contact_number: clientGpData.contact_number,
          address: clientGpData.address,
        }),
        child_details: JSON.stringify({
          name: childDetails.name,
          email: childDetails.email,
          contact_number: childDetails.contact_number,
        }),
      },
      contact_methods: allowedContactTypes,
    }

    try {
      const response = await axios.post('/clients/create', data)
      setShowLoader(false)
      if (response.data.error === false) {
        createInfo('success', `Created Client: ${clientData.name}`)
        navigate(`/clients`)
      }
    } catch (error) {
      setShowLoader(false)
    }
  }

  const updateClientAddress = (address) => {
    let addressString = ''

    Object.keys(address).forEach((key, index) => {
      if (key === 'value') {
        return
      }

      if (!address[key] || address[key].trim() === '') {
        return
      }

      addressString += address[key]

      if (index !== Object.keys(address).length - 1) {
        addressString += ', '
      }
    })

    clientData.address = addressString
  }

  const updateEmergencyContactAddress = (address) => {
    console.log(address)
    let addressString = ''

    Object.keys(address).forEach((key, index) => {
      if (key === 'value') {
        return
      }

      if (!address[key] || address[key].trim() === '') {
        return
      }

      addressString += address[key]

      if (index !== Object.keys(address).length - 1) {
        addressString += ', '
      }
    })

    emergencyContact.address = addressString
  }

  const updateGPAddress = (address) => {
    let addressString = ''

    Object.keys(address).forEach((key, index) => {
      if (key === 'value') {
        return
      }

      if (!address[key] || address[key].trim() === '') {
        return
      }

      addressString += address[key]

      if (index !== Object.keys(address).length - 1) {
        addressString += ', '
      }
    })

    clientGpData.address = addressString
  }

  const getContactMethods = async () => {
    try {
      const response = await axios.get(`/contact-methods/all`)
      setContactMethods(response.data.result)
    } catch (error) {}
  }

  const getRegions = async () => {
    try {
      const response = await axios.get(`/regions/all`)
      setRegions(response.data.result)
    } catch (error) {}
  }

  useEffect(() => {
    getContactMethods()
    getRegions()
    setPhoneNumber('+44')
    setChildPhoneNumber('+44')
    setEmergencyPhoneNumber('+44')
  }, [])

  return (
    <section>
      <MainTitle title="Create Client" backButton />

      <section>
        <form className="w-full relative" onSubmit={createClient}>
          <div className="my-4">
            <div className="text-md font-bold mb-2">Client Type</div>
            <div className="toggle flex items-center">
              <span className="text-sm mr-3">Adult</span>
              <label className="flex items-center relative w-max select-none">
                <input
                  onChange={(e) => setToggleChild(e.target.checked)}
                  type="checkbox"
                  className="appearance-none transition-colors cursor-pointer w-[60px] h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue focus:ring-blue-500 bg-gray-300 shadow-inner"
                />
                <span className="w-6 h-6 right-8 absolute rounded-full transform transition-transform bg-white" />
              </label>
              <span className="text-sm ml-3">Child</span>
            </div>
          </div>
          <hr className="bg-gray-300 w-full my-4" />
          {!toggleChild ? (
            <div className="mt-6">
              <div>
                <h3 className="text-md font-bold mb-2">Client Info</h3>

                <div className="flex">
                  <div className="mb-3 w-1/2 mr-2">
                    <label
                      htmlFor="Client_name"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Client Name
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clientData.name}
                        type="text"
                        name="Client_name"
                        required
                        id="Client_name"
                        onChange={(e) => (clientData.name = e.target.value)}
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                  <div className="mb-3 w-1/2 ml-2">
                    <label
                      htmlFor="Client_email"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Client Email
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clientData.email}
                        type="Client_email"
                        required
                        name="email"
                        id="Client_email"
                        onChange={(e) => (clientData.email = e.target.value)}
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                      <p
                        id="emailnote"
                        className={
                          !validEmail ? 'instructions failed' : 'offscreen'
                        }
                      >
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        This email already exists, please enter a different one.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="w-1/2 ml-2"></div>
                </div>
              </div>
              <hr className="bg-gray-300 w-full my-4" />
              <div>
                <h3 className="text-md font-bold mb-2">Client Details</h3>
                <div className="flex">
                  <div className="mb-3 w-1/2 mr-2">
                    <label
                      htmlFor="Client_number"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Client Phone Number{' '}
                      <span className="text-blue-500 text-xs">
                        (Please use +44 and no spaces)
                      </span>
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clientData.phone_number}
                        type="text"
                        required
                        minLength="3"
                        name="Client_number"
                        id="Client_number"
                        value={phoneNumber}
                        onChange={(e) => {
                          handlePhoneNumberChange(e)
                          clientData.phone_number = e.target.value
                        }}
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                      {phoneNumber.length > 3 && (
                        <>
                          {isValid ? (
                            <span className="text-green-500 text-sm">
                              Valid phone number
                            </span>
                          ) : (
                            <span className="text-red-600 text-sm">
                              Invalid phone number
                            </span>
                          )}
                        </>
                      )}
                      <p
                        id="emailnote"
                        className={
                          !validPhoneNumber
                            ? 'instructions failed'
                            : 'offscreen'
                        }
                      >
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        This Phone number already exists, please enter a
                        different one.
                      </p>
                    </div>
                  </div>
                  <div className="mb-3 w-1/2 ml-2">
                    <label
                      htmlFor="date_of_birth"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Date Of Birth
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <input
                        defaultValue={clientData.date_of_birth}
                        type="date"
                        min="1900-01-01"
                        required
                        name="date_of_birth"
                        id="date_of_birth"
                        onChange={(e) =>
                          (clientData.date_of_birth = e.target.value)
                        }
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex">
                  <div className="mb-3 w-1/2 mr-2">
                    <label
                      htmlFor="client_address"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <AutoComplete
                        defaultValue={clientData.address}
                        type="text"
                        name="client_address"
                        required
                        id="client_address"
                        className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        apiKey={GOOGLE_API_KEY}
                        onPlaceSelected={(place) => {
                          const addressFormatted = place.formatted_address
                          const segments = place.address_components
                          let data = {}
                          segments.forEach((key) => {
                            const type = key.types[0]
                            data[type] = key.long_name
                          })

                          const formattedSegments = addressFormatted.split(', ')

                          const address = {
                            address_line_1: data['street_number']
                              ? data['street_number'] + ' ' + data['route']
                              : formattedSegments[1],
                            address_line_2:
                              data['locality'] ?? data['political'],
                            city: data['postal_town'],
                            county: data['administrative_area_level_2'],
                            country: data['country'],
                            postcode: data['postal_code'],
                          }

                          setClientAddress(address)
                          updateClientAddress(address)
                        }}
                        options={{
                          types: [],
                          componentRestrictions: { country: 'gb' },
                        }}
                      />
                    </div>
                  </div>
                </div>
                {clientAddress.address_line_1 && (
                  <>
                    <div className="flex">
                      <div className="mb-3 w-1/2 mr-2">
                        <label
                          htmlFor="client_address_line_1"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Address Line 1
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            value={clientAddress.address_line_1 || ''}
                            type="text"
                            name="client_address_line_1"
                            required
                            id="client_address_line_1"
                            onChange={(e) => {
                              const address = clientAddress
                              address.address_line_1 = e.target.value
                              setClientAddress({
                                ...clientAddress,
                                address_line_1: e.target.value,
                              })
                              updateClientAddress(address)
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3 w-1/2 ml-2">
                        <label
                          htmlFor="client_address_line_2"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Address Line 2
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            value={clientAddress.address_line_2 || ''}
                            type="text"
                            name="client_address_line_2"
                            required
                            id="client_address_line_2"
                            onChange={(e) => {
                              const address = clientAddress
                              address.address_line_2 = e.target.value
                              setClientAddress({
                                ...clientAddress,
                                address_line_2: e.target.value,
                              })
                              updateClientAddress(address)
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="mb-3 w-1/2 mr-2">
                        <label
                          htmlFor="client_city"
                          className="block text-xs font-medium text-gray-700"
                        >
                          City
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            value={clientAddress.city || ''}
                            type="text"
                            name="client_city"
                            required
                            id="client_city"
                            onChange={(e) => {
                              const address = clientAddress
                              address.city = e.target.value
                              setClientAddress({
                                ...clientAddress,
                                city: e.target.value,
                              })
                              updateClientAddress(address)
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3 w-1/2 ml-2">
                        <label
                          htmlFor="client_county"
                          className="block text-xs font-medium text-gray-700"
                        >
                          County
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            value={clientAddress.county || ''}
                            type="text"
                            name="client_county"
                            required
                            id="client_county"
                            onChange={(e) => {
                              const address = clientAddress
                              address.county = e.target.value
                              setClientAddress({
                                ...clientAddress,
                                county: e.target.value,
                              })
                              updateClientAddress(address)
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="mb-3 w-1/2 mr-2">
                        <label
                          htmlFor="client_postcode"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Postcode
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            value={clientAddress.postcode || ''}
                            type="text"
                            name="client_postcode"
                            required
                            id="client_postcode"
                            onChange={(e) => {
                              const address = clientAddress
                              address.postcode = e.target.value
                              setClientAddress({
                                ...clientAddress,
                                postcode: e.target.value,
                              })
                              updateClientAddress(address)
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3 w-1/2 ml-2">
                        <label
                          htmlFor="client_country"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            value={clientAddress.country || ''}
                            type="text"
                            name="client_country"
                            required
                            id="client_country"
                            onChange={(e) => {
                              const address = clientAddress
                              address.country = e.target.value
                              setClientAddress({
                                ...clientAddress,
                                country: e.target.value,
                              })
                              updateClientAddress(address)
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="flex">
                  <div className="mb-3 w-1/2 mr-2"></div>
                  {/* <div className="mb-3 w-1/2 ml-2">
                    <label
                      htmlFor="client_region"
                      className="block text-xs font-medium text-gray-700"
                    >
                      Client Region
                    </label>
                    <div className="mt-1 relative rounded-md">
                      <select
                        className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        required
                        onChange={(e) => (clientData.region = e.target.value)}
                      >
                        <option disabled selected>
                          Select Region
                        </option>
                        {regions?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
              <hr className="bg-gray-300 w-full my-4" />
              <div>
                <h3 className="text-md font-bold mb-2">
                  Emergency Contact Details
                </h3>
                <div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="emergency_contact_name"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Contact Name
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={emergencyContact.name}
                          type="text"
                          name="ec_name"
                          id="ec_name"
                          onChange={(e) =>
                            (emergencyContact.name = e.target.value)
                          }
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="ec_contact_number"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Contact Number{' '}
                        <span className="text-blue-500 text-xs">
                          (Please use +44 and no spaces)
                        </span>
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={emergencyContact.contact_number}
                          type="text"
                          required
                          minLength="3"
                          name="ec_number"
                          id="ec_number"
                          value={emergencyPhoneNumber}
                          onChange={(e) => {
                            handleEmergencyPhoneNumberChange(e)
                            emergencyContact.contact_number = e.target.value
                          }}
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                        {emergencyPhoneNumber.length > 3 && (
                          <>
                            {isValid ? (
                              <span className="text-green-500 text-sm">
                                Valid phone number
                              </span>
                            ) : (
                              <span className="text-red-600 text-sm">
                                Invalid phone number
                              </span>
                            )}
                          </>
                        )}
                        {/* <p
                          id="emailnote"
                          className={
                            !validPhoneNumber
                              ? 'instructions failed'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          This Phone number already exists, please enter a
                          different one.
                        </p> */}
                      </div>
                    </div>
                    <div className="mb-3 w-1/2 ml-2">
                      <label
                        htmlFor="ec_relationship"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Relationship to Client
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={emergencyContact.relationship}
                          type="text"
                          name="ec_relationship"
                          id="ec_relationship"
                          onChange={(e) =>
                            (emergencyContact.relationship = e.target.value)
                          }
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="client_address"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Contact Address
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <AutoComplete
                          defaultValue={emergencyContact.address}
                          type="text"
                          name="client_address"
                          required
                          id="client_address"
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          apiKey={GOOGLE_API_KEY}
                          onPlaceSelected={(place) => {
                            const addressFormatted = place.formatted_address
                            const segments = place.address_components
                            let data = {}
                            segments.forEach((key) => {
                              const type = key.types[0]
                              data[type] = key.long_name
                            })

                            const formattedSegments =
                              addressFormatted.split(', ')

                            const address = {
                              address_line_1: data['street_number']
                                ? data['street_number'] + ' ' + data['route']
                                : formattedSegments[1],
                              address_line_2:
                                data['locality'] ?? data['political'],
                              city: data['postal_town'],
                              county: data['administrative_area_level_2'],
                              country: data['country'],
                              postcode: data['postal_code'],
                            }

                            setEmergencyContactAddress(address)
                            updateEmergencyContactAddress(address)
                          }}
                          options={{
                            types: [],
                            componentRestrictions: { country: 'gb' },
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {emergencyContactAddress.address_line_1 && (
                    <>
                      <div className="flex">
                        <div className="mb-3 w-1/2 mr-2">
                          <label
                            htmlFor="client_address_line_1"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Address Line 1
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={
                                emergencyContactAddress.address_line_1 || ''
                              }
                              type="text"
                              name="client_address_line_1"
                              required
                              id="client_address_line_1"
                              onChange={(e) => {
                                const address = emergencyContactAddress
                                address.address_line_1 = e.target.value
                                setEmergencyContactAddress({
                                  ...emergencyContactAddress,
                                  address_line_1: e.target.value,
                                })
                                updateEmergencyContactAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                        <div className="mb-3 w-1/2 ml-2">
                          <label
                            htmlFor="client_address_line_2"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Address Line 2
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={
                                emergencyContactAddress.address_line_2 || ''
                              }
                              type="text"
                              name="client_address_line_2"
                              required
                              id="client_address_line_2"
                              onChange={(e) => {
                                const address = emergencyContactAddress
                                address.address_line_2 = e.target.value
                                setEmergencyContactAddress({
                                  ...emergencyContactAddress,
                                  address_line_2: e.target.value,
                                })
                                updateEmergencyContactAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="mb-3 w-1/2 mr-2">
                          <label
                            htmlFor="client_city"
                            className="block text-xs font-medium text-gray-700"
                          >
                            City
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={emergencyContactAddress.city || ''}
                              type="text"
                              name="client_city"
                              required
                              id="client_city"
                              onChange={(e) => {
                                const address = emergencyContactAddress
                                address.city = e.target.value
                                setEmergencyContactAddress({
                                  ...emergencyContactAddress,
                                  city: e.target.value,
                                })
                                updateEmergencyContactAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                        <div className="mb-3 w-1/2 ml-2">
                          <label
                            htmlFor="client_county"
                            className="block text-xs font-medium text-gray-700"
                          >
                            County
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={emergencyContactAddress.county || ''}
                              type="text"
                              name="client_county"
                              required
                              id="client_county"
                              onChange={(e) => {
                                const address = emergencyContactAddress
                                address.county = e.target.value
                                setEmergencyContactAddress({
                                  ...emergencyContactAddress,
                                  county: e.target.value,
                                })
                                updateEmergencyContactAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="mb-3 w-1/2 mr-2">
                          <label
                            htmlFor="client_postcode"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Postcode
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={emergencyContactAddress.postcode || ''}
                              type="text"
                              name="client_postcode"
                              required
                              id="client_postcode"
                              onChange={(e) => {
                                const address = emergencyContactAddress
                                address.postcode = e.target.value
                                setEmergencyContactAddress({
                                  ...emergencyContactAddress,
                                  postcode: e.target.value,
                                })
                                updateEmergencyContactAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                        <div className="mb-3 w-1/2 ml-2">
                          <label
                            htmlFor="client_country"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Country
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={emergencyContactAddress.country || ''}
                              type="text"
                              name="client_country"
                              required
                              id="client_country"
                              onChange={(e) => {
                                const address = emergencyContactAddress
                                address.country = e.target.value
                                setEmergencyContactAddress({
                                  ...emergencyContactAddress,
                                  country: e.target.value,
                                })
                                updateEmergencyContactAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <hr className="bg-gray-300 w-full my-4" />
              <div>
                <h3 className="text-md font-bold mb-2">Client GP Details</h3>
                <div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="gp_name"
                        className="block text-xs font-medium text-gray-700"
                      >
                        GP Name
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={clientGpData.name}
                          type="text"
                          name="gp_name"
                          id="gp_name"
                          onChange={(e) => (clientGpData.name = e.target.value)}
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                    <div className="mb-3 w-1/2 ml-2"></div>
                  </div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="gp_address"
                        className="block text-xs font-medium text-gray-700"
                      >
                        GP Address
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <AutoComplete
                          defaultValue={clientGpData.address}
                          type="address"
                          name="gp_address"
                          id="gp_address"
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          apiKey={GOOGLE_API_KEY}
                          onPlaceSelected={(place) => {
                            const addressFormatted = place.formatted_address
                            const segments = place.address_components
                            let data = {}
                            segments.forEach((key) => {
                              const type = key.types[0]
                              data[type] = key.long_name
                            })

                            const formattedSegments =
                              addressFormatted.split(', ')

                            const address = {
                              address_line_1: data['street_number']
                                ? data['street_number'] + ' ' + data['route']
                                : formattedSegments[1],
                              address_line_2:
                                data['locality'] ?? data['political'],
                              city: data['postal_town'],
                              county: data['administrative_area_level_2'],
                              country: data['country'],
                              postcode: data['postal_code'],
                            }

                            setGPAddress(address)
                            updateGPAddress(address)
                          }}
                          options={{
                            types: [],
                            componentRestrictions: { country: 'gb' },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {gpAddress.address_line_1 && (
                    <>
                      <div className="flex">
                        <div className="mb-3 w-1/2 mr-2">
                          <label
                            htmlFor="gp_address_line_1"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Address Line 1
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={gpAddress.address_line_1 || ''}
                              type="text"
                              name="gp_address_line_1"
                              required
                              id="gp_address_line_1"
                              onChange={(e) => {
                                const address = gpAddress
                                address.address_line_1 = e.target.value
                                setGPAddress({
                                  ...gpAddress,
                                  address_line_1: e.target.value,
                                })
                                updateGPAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                        <div className="mb-3 w-1/2 ml-2">
                          <label
                            htmlFor="gp_address_line_2"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Address Line 2
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={gpAddress.address_line_2 || ''}
                              type="text"
                              name="gp_address_line_2"
                              required
                              id="gp_address_line_2"
                              onChange={(e) => {
                                const address = gpAddress
                                address.address_line_2 = e.target.value
                                setGPAddress({
                                  ...gpAddress,
                                  address_line_2: e.target.value,
                                })
                                updateGPAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mb-3 w-1/2 mr-2">
                          <label
                            htmlFor="gp_city"
                            className="block text-xs font-medium text-gray-700"
                          >
                            City
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={gpAddress.city || ''}
                              type="text"
                              name="gp_city"
                              required
                              id="gp_city"
                              onChange={(e) => {
                                const address = gpAddress
                                address.city = e.target.value
                                setGPAddress({
                                  ...gpAddress,
                                  city: e.target.value,
                                })
                                updateGPAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                        <div className="mb-3 w-1/2 ml-2">
                          <label
                            htmlFor="gp_county"
                            className="block text-xs font-medium text-gray-700"
                          >
                            County
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={gpAddress.county || ''}
                              type="text"
                              name="gp_county"
                              required
                              id="gp_county"
                              onChange={(e) => {
                                const address = gpAddress
                                address.county = e.target.value
                                setGPAddress({
                                  ...gpAddress,
                                  county: e.target.value,
                                })
                                updateGPAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="mb-3 w-1/2 mr-2">
                          <label
                            htmlFor="gp_postcode"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Post Code
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={gpAddress.postcode || ''}
                              type="text"
                              name="gp_postcode"
                              required
                              id="gp_postcode"
                              onChange={(e) => {
                                const address = gpAddress
                                address.postcode = e.target.value
                                setGPAddress({
                                  ...gpAddress,
                                  postcode: e.target.value,
                                })
                                updateGPAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                        <div className="mb-3 w-1/2 ml-2">
                          <label
                            htmlFor="gp_country"
                            className="block text-xs font-medium text-gray-700"
                          >
                            Country
                          </label>
                          <div className="mt-1 relative rounded-md">
                            <input
                              value={gpAddress.country || ''}
                              type="text"
                              name="gp_country"
                              required
                              id="gp_country"
                              onChange={(e) => {
                                const address = gpAddress
                                address.country = e.target.value
                                setGPAddress({
                                  ...gpAddress,
                                  country: e.target.value,
                                })
                                updateGPAddress(address)
                              }}
                              className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <hr className="bg-gray-300 w-full my-4" />
              <div>
                <h3 className="text-md font-bold mb-2">Contact Preferences</h3>
                <div className="flex items-center justify-between space-x-6">
                    <div className="mb-3 w-1/2">
                      <label
                        htmlFor="contact_time"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Preferred Contact Time
                      </label>
                      <div className="mt-1 relative rounded-md mr-2">
                        <input
                          defaultValue={clientData.contact_time}
                          type="text"
                          name="contact_time"
                          id="contact_time"
                          onChange={(e) =>
                            (clientData.contact_time = e.target.value)
                          }
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                    <div className="mb-3 w-1/2">
                      <div className="font-medium text-gray-700 text-xs mr-2 min-w-[200px] mb-2">
                        Send automatic notifications?
                      </div>
                      <div className='flex items-center'>
                          <span className='pr-2 text-gray-700 text-sm'>No</span>
                          <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 data-[checked]:bg-blue-500"
                          >
                            <span className="sr-only">Use setting</span>
                            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
                              <span
                                aria-hidden="true"
                                className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                              >
                                <svg fill="none" viewBox="0 0 12 12" className="h-3 w-3 text-gray-400">
                                  <path
                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span
                                aria-hidden="true"
                                className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                              >
                                <svg fill="currentColor" viewBox="0 0 12 12" className="h-3 w-3 text-indigo-600">
                                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                </svg>
                              </span>
                            </span>
                          </Switch>
                          <span className='pl-2 text-gray-700 text-sm'>Yes</span>
                      </div>
                    </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="contact_time"
                    className="block text-xs font-medium text-gray-700 flex-col pb-2"
                  >
                    Allowed Contact Methods
                  </label>
                  <div className="mb-3 w-1/2 mr-2 flex">
                    {contactMethods?.map((method) => {
                      return (
                        <div className="items-center flex">
                          <input
                            type="checkbox"
                            id="contact_method"
                            value={method.title}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                            onChange={() => updateContactMethod(method)}
                          />
                          <label
                            htmlFor="clinic_details"
                            className="ml-2 mr-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {method.title}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <hr className="bg-gray-300 w-full my-4" />
              <div className="flex mt-2">
                <button
                  type="submit"
                  className={` ${!isValid ? 'btn disabled' : 'btn'}`}
                  disabled={!isValid}
                >
                  Create Client
                </button>
              </div>
            </div>
          ) : (
            //CHILD DETAILS SECTION
            <>
              <div className="py-3 px-4 bg-teal-200 rounded-lg mb-4 text-sm">
                <span className="font-medium">
                  You are creating a <u>child client</u>.
                </span>{' '}
                Please note that the information required for this client will
                differ from that of an adult.
              </div>
              <div className="mt-6">
                <div>
                  <h3 className="text-md font-bold mb-2">Client Info</h3>

                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="Client_name"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Client Name
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={clientData.name}
                          type="text"
                          name="Client_name"
                          required
                          id="Client_name"
                          onChange={(e) => (clientData.name = e.target.value)}
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                    <div className="mb-3 w-1/2 ml-2">
                      <label
                        htmlFor="Client_email"
                        className="block text-xs font-medium text-gray-700"
                      >
                        School Email
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={clientData.email}
                          type="Client_email"
                          required
                          name="email"
                          id="Client_email"
                          onChange={(e) => (clientData.email = e.target.value)}
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                        <p
                          id="emailnote"
                          className={
                            !validEmail ? 'instructions failed' : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          This email already exists, please enter a different
                          one.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="w-1/2 ml-2"></div>
                  </div>
                </div>
                <hr className="bg-gray-300 w-full my-4" />
                <div>
                  <h3 className="text-md font-bold mb-2">Contact Details</h3>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="Client_number"
                        className="block text-xs font-medium text-gray-700"
                      >
                        School Phone Number{' '}
                        <span className="text-blue-500 text-xs">
                          (Please use +44 and no spaces)
                        </span>
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={clientData.phone_number}
                          type="text"
                          required
                          minLength="3"
                          name="Client_number"
                          id="Client_number"
                          value={phoneNumber}
                          onChange={(e) => {
                            handlePhoneNumberChange(e)
                            clientData.phone_number = e.target.value
                          }}
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                        {phoneNumber.length > 3 && (
                          <>
                            {isValid ? (
                              <span className="text-green-500 text-sm">
                                Valid phone number
                              </span>
                            ) : (
                              <span className="text-red-600 text-sm">
                                Invalid phone number
                              </span>
                            )}
                          </>
                        )}
                        <p
                          id="emailnote"
                          className={
                            !validPhoneNumber
                              ? 'instructions failed'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          This Phone number already exists, please enter a
                          different one.
                        </p>
                      </div>
                    </div>
                    <div className="mb-3 w-1/2 ml-2">
                      <label
                        htmlFor="client_address"
                        className="block text-xs font-medium text-gray-700"
                      >
                        School Address
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <AutoComplete
                          defaultValue={clientData.address}
                          type="text"
                          name="client_address"
                          required
                          id="client_address"
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          apiKey={GOOGLE_API_KEY}
                          onPlaceSelected={(place) => {
                            clientData.address = place.formatted_address
                          }}
                          options={{
                            types: [],
                            componentRestrictions: { country: 'gb' },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="date_of_birth"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Client Date Of Birth
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={clientData.date_of_birth}
                          type="date"
                          min="1900-01-01"
                          required
                          name="date_of_birth"
                          id="date_of_birth"
                          onChange={(e) =>
                            (clientData.date_of_birth = e.target.value)
                          }
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                    <div className="mb-3 w-1/2 ml-2">
                      <label
                        htmlFor="client_region"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Client Region
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <select
                          className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          required
                          onChange={(e) => (clientData.region = e.target.value)}
                        >
                          <option disabled selected>
                            Select Region
                          </option>
                          {regions?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-gray-300 w-full my-4" />
                <div>
                  <h3 className="text-md font-bold mb-2">
                    Emergency Contact Details
                  </h3>
                  <div>
                    <div className="flex">
                      <div className="mb-3 w-1/2 mr-2">
                        <label
                          htmlFor="emergency_contact_name"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Contact Name
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            defaultValue={emergencyContact.name}
                            type="text"
                            name="ec_name"
                            id="ec_name"
                            onChange={(e) =>
                              (emergencyContact.name = e.target.value)
                            }
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3 w-1/2 ml-2">
                        <label
                          htmlFor="ec_address"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Contact Address
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <AutoComplete
                            defaultValue={emergencyContact.address}
                            type="address"
                            name="ec_address"
                            id="ec_address"
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                            apiKey={GOOGLE_API_KEY}
                            onPlaceSelected={(place) => {
                              emergencyContact.address = place.formatted_address
                            }}
                            options={{
                              types: [],
                              componentRestrictions: { country: 'gb' },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="mb-3 w-1/2 mr-2">
                        <label
                          htmlFor="ec_contact_number"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Contact Number{' '}
                          <span className="text-blue-500 text-xs">
                            (Please use +44 and no spaces)
                          </span>
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            defaultValue={emergencyContact.contact_number}
                            type="text"
                            required
                            minLength="3"
                            name="ec_number"
                            id="ec_number"
                            value={emergencyPhoneNumber}
                            onChange={(e) => {
                              handleEmergencyPhoneNumberChange(e)
                              emergencyContact.contact_number = e.target.value
                            }}
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                          {emergencyPhoneNumber.length > 3 && (
                            <>
                              {isValid ? (
                                <span className="text-green-500 text-sm">
                                  Valid phone number
                                </span>
                              ) : (
                                <span className="text-red-600 text-sm">
                                  Invalid phone number
                                </span>
                              )}
                            </>
                          )}
                          <p
                            id="emailnote"
                            className={
                              !validPhoneNumber
                                ? 'instructions failed'
                                : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            This Phone number already exists, please enter a
                            different one.
                          </p>
                        </div>
                      </div>
                      <div className="mb-3 w-1/2 ml-2">
                        <label
                          htmlFor="ec_relationship"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Relationship to Client
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            defaultValue={emergencyContact.relationship}
                            type="text"
                            name="ec_relationship"
                            id="ec_relationship"
                            onChange={(e) =>
                              (emergencyContact.relationship = e.target.value)
                            }
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-gray-300 w-full my-4" />
                <div>
                  <h3 className="text-md font-bold mb-2">
                    Safeguard Contact Info
                  </h3>
                  <div>
                    <div className="flex">
                      <div className="mb-3 w-1/2 mr-2">
                        <label
                          htmlFor="gp_name"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Lead Name
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            defaultValue={childDetails.name}
                            type="text"
                            name="gp_name"
                            id="gp_name"
                            onChange={(e) =>
                              (childDetails.name = e.target.value)
                            }
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                        </div>
                      </div>
                      <div className="mb-3 w-1/2 ml-2">
                        <label
                          htmlFor="Client_email"
                          className="block text-xs font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <input
                            defaultValue={childDetails.email}
                            type="Client_email"
                            required
                            name="email"
                            id="Client_email"
                            onChange={(e) =>
                              (childDetails.email = e.target.value)
                            }
                            className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          />
                          <p
                            id="emailnote"
                            className={
                              !validEmail ? 'instructions failed' : 'offscreen'
                            }
                          >
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            This email already exists, please enter a different
                            one.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mb-3 w-1/2 mr-2">
                      <label
                        htmlFor="c_contact_number"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Contact Number{' '}
                        <span className="text-blue-500 text-xs">
                          (Please use +44 and no spaces)
                        </span>
                      </label>
                      <div className="mt-1 relative rounded-md">
                        <input
                          defaultValue={childDetails.contact_number}
                          type="text"
                          required
                          minLength="3"
                          name="c_number"
                          id="c_number"
                          value={childPhoneNumber}
                          onChange={(e) => {
                            handleChildPhoneNumberChange(e)
                            childDetails.contact_number = e.target.value
                          }}
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                        {childPhoneNumber.length > 3 && (
                          <>
                            {isValid ? (
                              <span className="text-green-500 text-sm">
                                Valid phone number
                              </span>
                            ) : (
                              <span className="text-red-600 text-sm">
                                Invalid phone number
                              </span>
                            )}
                          </>
                        )}
                        <p
                          id="emailnote"
                          className={
                            !validPhoneNumber
                              ? 'instructions failed'
                              : 'offscreen'
                          }
                        >
                          <FontAwesomeIcon icon={faCircleExclamation} />
                          This Phone number already exists, please enter a
                          different one.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-gray-300 w-full my-4" />
                <div>
                  <h3 className="text-md font-bold mb-2">
                    Contact Preferences
                  </h3>
                  <div className="flex items-center justify-between space-x-6">
                    <div className="mb-3 w-1/2">
                      <label
                        htmlFor="contact_time"
                        className="block text-xs font-medium text-gray-700"
                      >
                        Preferred Contact Time
                      </label>
                      <div className="mt-1 relative rounded-md mr-2">
                        <input
                          defaultValue={clientData.contact_time}
                          type="text"
                          name="contact_time"
                          id="contact_time"
                          onChange={(e) =>
                            (clientData.contact_time = e.target.value)
                          }
                          className="px-2 text-sm py-2 block w-full border border-gray-300 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                    <div className="mb-3 w-1/2">
                      <div className="font-medium text-gray-700 text-xs mr-2 min-w-[200px] mb-2">
                        Send automatic notifications?
                      </div>
                      <div className='flex items-center'>
                          <span className='pr-2 text-gray-700 text-sm'>No</span>
                          <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 data-[checked]:bg-blue-500"
                          >
                            <span className="sr-only">Use setting</span>
                            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
                              <span
                                aria-hidden="true"
                                className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                              >
                                <svg fill="none" viewBox="0 0 12 12" className="h-3 w-3 text-gray-400">
                                  <path
                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span
                                aria-hidden="true"
                                className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                              >
                                <svg fill="currentColor" viewBox="0 0 12 12" className="h-3 w-3 text-indigo-600">
                                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                </svg>
                              </span>
                            </span>
                          </Switch>
                          <span className='pl-2 text-gray-700 text-sm'>Yes</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="contact_time"
                      className="block text-xs font-medium text-gray-700 flex-col pb-2"
                    >
                      Allowed Contact Methods
                    </label>
                    <div className="mb-3 w-1/2 mr-2 flex">
                      {contactMethods?.map((method) => {
                        return (
                          <div className="items-center flex">
                            <input
                              type="checkbox"
                              id="contact_method"
                              value={method.title}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                              onChange={() => updateContactMethod(method)}
                            />
                            <label
                              htmlFor="clinic_details"
                              className="ml-2 mr-4 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {method.title}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>      
                </div>
                <hr className="bg-gray-300 w-full my-4" />
                <div className="flex mt-2">
                  <button
                    type="submit"
                    className={` ${!isValid ? 'btn disabled' : 'btn'}`}
                    disabled={!isValid}
                  >
                    Create Client
                  </button>
                </div>
              </div>
            </>
          )}
        </form>
      </section>
    </section>
  )
}

export default CreateClient
