import MainTitle from "../../components/MainTitle";

import { NavLink } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import CardButton from "../../components/CardButton";

import { faSliders, faUsers } from "@fortawesome/free-solid-svg-icons";

function Settings() {
  const { can } = useAuth();
  return (
    <section>
      <MainTitle title="Settings" />

      <ul className="flex just-between flex-wrap">
        {can("access permissions") && (
          <li className="w-1/4 mb-6 mr-3">
            <NavLink to="permissions">
              <CardButton icon={faSliders} title="Permissions" />
            </NavLink>
          </li>
        )}

        {can("access all users") && (
          <li className="w-1/4 mb-6 mx-3">
            <NavLink to="users">
              <CardButton icon={faUsers} title="Users" />
            </NavLink>
          </li>
        )}
      </ul>
    </section>
  );
}

export default Settings;
