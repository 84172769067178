import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faUsers } from '@fortawesome/free-solid-svg-icons'
function Tools() {
  const { can } = useAuth()
  return (
    <section>
      <MainTitle title="Tools" />
      <section>
        <ul className="flex just-between flex-wrap">
          {can('view notifications') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="notifications">
                <CardButton icon={faBell} title="Notifications" />
              </NavLink>
            </li>
          )}
        </ul>
      </section>
    </section>
  )
}

export default Tools
