import { useState } from 'react'
import MainTitle from '../../components/MainTitle'
import { useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faClock,
  faPhone,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import Datepicker from 'react-tailwindcss-datepicker'

function Dashboard() {
  const { auth, setShowLoader } = useAuth()

  const [nextAppointment, setNextAppointment] = useState({})
  const [appointments, setAppointments] = useState([])
  const [newMessages, setNewMessages] = useState([])
  const [yourClients, setYourClients] = useState([])
  const [value, setValue] = useState({
    startDate: moment(),
    endDate: moment().add(10, 'days'),
  })
  const [days, setDays] = useState({
    startDate: moment().subtract(7, 'days'),
    endDate: moment(),
  })
  const navigate = useNavigate();

  const axios = useAxiosPrivate()

  const getNextAppointment = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/users/${auth.user.id}/next-appointment`
      )
      if (!response.data.error) {
        setNextAppointment(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getAppointments = async (start, end) => {
    try {
      const response = await axios.get(
        `/users/${auth.user.id}/sessions/${moment(start).format(
          'YYYY-MM-DD'
        )}/${moment(end).format('YYYY-MM-DD')}`
      )
      if (response.data && response.data.result) {
        const sortedAppointments = response.data.result.sort((first, second) => 
          new Date(first.start_time) - new Date(second.start_time)
        );
        setAppointments(sortedAppointments);
      }
    } catch (error) {}
  }

  const getNewMessages = async (start, end) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/users/${auth.user.id}/new-messages/${moment(start).format(
          'YYYY-MM-DD'
        )}/${moment(end).format('YYYY-MM-DD')}`
      )
      if (!response.data.error) {
        setNewMessages(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }


  const getYourClients = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(`/clients/all/by-user/${auth.user.id}`)
      if (!response.data.error) {
        setYourClients(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const handleValueChange = (newValue) => {
    setValue(newValue)
    getAppointments(newValue.startDate, newValue.endDate)
  }

  const handleDayChange = (newValue) => {
    setDays(newValue)
    getNewMessages(newValue.startDate, newValue.endDate)
  }
  

  const viewMessage = (id, thread_reference, name) => {
    return navigate(`/clients/${id}/messages?thread=${thread_reference}`, {state:{name:name, id: id, reference: thread_reference}})
  }

  useEffect(() => {
    getYourClients()
    getNextAppointment()
    getNewMessages(days.startDate, days.endDate)
    getAppointments(value.startDate, value.endDate)

    const interval = setInterval(() => {
      getNextAppointment()
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  return (
    <section>
      <MainTitle title="Dashboard" />

      <section className="grid grid-cols-2 gap-6">
        <article className="border-l-[5px] p-4 bg-white flex-1 border-l-wba-primary rounded-md shadow-md">
          <h3 className="text-xl font-medium mb-4 flex items-center">
            <p>Next Appointment</p>
          </h3>
          {nextAppointment && nextAppointment.id ? (
            <div className="border-t border-slate-200 py-3">
              <p className="mb-2">
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="text-slate-400 mr-2 text-lg"
                />
                {moment(nextAppointment.start_time).format('dddd Do MMMM')}
              </p>
              <p className="mb-2">
                <FontAwesomeIcon
                  icon={faClock}
                  className="text-slate-400 mr-2 text-lg"
                />
                {moment(nextAppointment.start_time).format('H:mm')} -{' '}
                {moment(nextAppointment.end_time).format('H:mm')}
              </p>
              <p className="mb-2">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-slate-400 mr-2 text-lg"
                />
                {nextAppointment.client.name}
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="text-slate-400 mr-2 text-lg"
                />
                {nextAppointment.service.title}
              </p>
              <Link
                to={`/clients/${nextAppointment.client.id}`}
                className="btn clear mt-4"
              >
                View Client
              </Link>
            </div>
          ) : (
            <div>
              <p className="text-sm text-slate-500">
                No Appointments Scheduled
              </p>
            </div>
          )}
        </article>

        <article className="p-4 bg-white flex-1 rounded-md shadow-md">
          <h3 className="text-xl font-medium mb-4 flex items-center">
            <p>Your Clients</p>
          </h3>
          {yourClients.length > 0 ? (
            <div>
              <ul className="max-h-[250px] min-h-[136px] overflow-y-scroll">
                {yourClients.map((client) => {
                  return (
                    <li key={client.id}>
                      <Link
                        to={`/clients/${client.id}`}
                        className="flex justify-between border-t border-slate-200 py-3 px-3 hover:bg-slate-100"
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-slate-400 mr-4"
                          />
                          <span>{client.name}</span>
                        </div>
                        <p>{client.reference}</p>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          ) : (
            <div>
              <p className="text-sm text-slate-500">No Clients</p>
            </div>
          )}
        </article>

        <article className="p-4 bg-white flex-1 rounded-md shadow-md">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-medium mb-4 flex items-center">
              <p>Appointments</p>
            </h3>
            <div className=" border border-gray-300 rounded-lg p-0.5">
              <Datepicker
                primaryColor={'blue'}
                value={value}
                onChange={handleValueChange}
                useRange={false}
              />
            </div>
          </div>
          {appointments.length > 0 ? (
            <div>
              <ul className="max-h-[250px] min-h-[136px] overflow-y-scroll mt-4">
                {appointments.map((appointment) => {
                  return (
                    <li key={appointment.id}>
                      <Link
                        to={`/clients/${appointment.client.id}`}
                        className="flex justify-between border-t border-slate-200 py-3 px-3 hover:bg-slate-100"
                      >
                        <p className="mb-2">
                          {moment(appointment.start_time).format(
                            'DD/MM/YY HH:mm'
                          )}
                        </p>
                        <p>{appointment.service.title}</p>
                        <div>
                          <span>{appointment.client.name}</span>
                        </div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          ) : (
            <div>
              <p className="text-sm text-slate-500">No Appointments</p>
            </div>
          )}
        </article>

        <article className="p-4 bg-white flex-1 rounded-md shadow-md">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-medium mb-4 flex items-center">
              <p>New Messages</p>
            </h3>
            <div className=" border border-gray-300 rounded-lg p-0.5">
              <Datepicker
                primaryColor={'blue'}
                value={days}
                onChange={handleDayChange}
                useRange={false}
              />
            </div>
          </div>
          {newMessages.length > 0 ? (
            <div>
              <ul className="max-h-[250px] min-h-[136px] overflow-y-scroll mt-4">
                {newMessages?.map((message) => {
                  return (
                    <li key={message.id} onClick={() => viewMessage(message.client_id, message.reference, message.name, message.client_id)} className='hover:bg-slate-100 cursor-pointer'>
                      {/* <Link
                        to={() => {veiwMessage(message.id, message.thread_id)}}
                        className=""
                      > */}
                       
                        <div className='border-b py-2'>
                          <div className='flex items-center justify-between'>
                            <div>{message.name}</div>
                            <div>{moment(message.created_at).format('DD/MM/YYYY')}</div>
                          </div>
                          <p className='text-xs'>{message.body}</p>
                        </div>

                      {/* </Link> */}
                    </li>
                  )
                })}
              </ul>
            </div>
            ) : (
              <div>
                <p className="text-sm text-slate-500">No New Messages</p>
              </div>
            )}
        </article>
      </section>
    </section>
  )
}

export default Dashboard
