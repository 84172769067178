/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import UserProfile from '../../components/UserProfile'
import MainTitle from '../../components/MainTitle'
import { Link, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserNav from '../../components/UserNav'
import Modal from '../../components/Modal'
import { Switch } from '@headlessui/react'

const User = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [userData, setUserData] = useState({})
  const { setShowLoader, can, createInfo } = useAuth()
  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')

  // Services
  const [services, setServices] = useState([])
  const [assignService, setAssignService] = useState({})
  const [assignedServices, setAssignedServices] = useState([])
  const [deleteService, setDeleteService] = useState({})
  const [videoSessionAvailability, setVideoSessionAvailability] =
    useState(false)

  // Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const getUserById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/users/${id}`)
      setUserData(response.data.result)
      if (response.data.result.video_sessions == 1) {
        setVideoSessionAvailability(true)
      } else {
        setVideoSessionAvailability(false)
      }
      setAssignedServices(response.data.result.services)
      setShowLoader(false)
    } catch (error) {}
  }

  const handleUpdateUser = (updatedUser) => {
    setUserData(updatedUser)
  }

  const assignServiceClicked = () => {
    getServices()
    setAssignService({})
    setModalTitle(`Assign Service`)
    setShowModal(true)
  }

  const deleteServiceClicked = (service) => {
    setModalTitle('Unassign Service: ' + service.title)
    setDeleteService({ ...service })
    setShowModal(true)
  }

  const getServices = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/services/by-user/${id}`)
      if (response.data.result) {
        setServices(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const resetModal = () => {
    setAssignService({})
    setDeleteService({})
    setShowModal(false)
  }

  const ModalBody = () => {
    if (deleteService.id) {
      return <DeleteServiceElement />
    }

    if (assignService) {
      return <AssignServiceElement />
    }
  }

  const AssignServiceElement = () => {
    const assignServiceSubmit = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      const data = JSON.parse(assignService)

      try {
        const response = await axios.post(`/users/assign-service`, {
          userId: userData.id,
          serviceId: data.id,
        })
        createInfo('success', `Assigned Service: ${data.title}`)
        setUserData(response.data.result)
        setAssignedServices(response.data.result.services)
        resetModal(false)
      } catch (error) {
        console.log(error)
      }

      setShowLoader(false)
    }

    return (
      <form onSubmit={assignServiceSubmit}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Service Name
          </label>
          <select
            defaultValue={assignService}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            required
            onChange={(e) => setAssignService(e.target.value)}
          >
            <option disabled value={{}}>
              Select Service
            </option>
            {services?.map((service) => {
              if (
                !assignedServices.find((assignedService) => {
                  return service.id === assignedService.id
                })
              ) {
                return (
                  <option key={service.id} value={JSON.stringify(service)}>
                    {service.title}
                  </option>
                )
              }
            })}
          </select>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Assign Service</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DeleteServiceElement = () => {
    const removeService = async () => {
      setShowLoader(true)
      try {
        const response = await axios.post(`/users/unassign-service`, {
          userId: userData.id,
          serviceId: deleteService.id,
        })
        createInfo('error', `Unassigned Service: ${deleteService.title}`)
        setUserData(response.data.result)
        setAssignedServices(response.data.result.services)
        resetModal(false)
      } catch (error) {}
      setShowLoader(false)
    }
    return (
      <form onSubmit={removeService} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to unassign this service?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Unassign Service
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  useEffect(() => {
    getUserById(id)
  }, [])

  const updateVideoAvailability = (e) => {
    let availability
    if (e) {
      availability = 1
    } else {
      availability = 0
    }
    setVideoSessionAvailability(e)
    axios
      .post(`/users/video-session-update`, {
        userId: userData.id,
        available: availability,
      })
      .then((response) => {
        createInfo('success', 'Updated Video Session Availability')
      })
      .catch((response) => {
        createInfo(
          'error',
          'There was an issue updating your Video Session Availability'
        )
      })
  }

  return (
    <div>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <MainTitle title="Profile" />
      <UserNav active="profile" userId={userData.id} />
      <UserProfile user={userData} onUpdateUser={handleUpdateUser} />

      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex mb-4">
          <p className="mr-4">Available for Video Sessions:</p>
          <Switch
            checked={videoSessionAvailability}
            onChange={updateVideoAvailability}
            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
          >
            <span className="sr-only">Available for Video Sessions</span>
            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
              <span
                aria-hidden="true"
                className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
              >
                <svg
                  fill="none"
                  viewBox="0 0 12 12"
                  className="h-3 w-3 text-gray-400"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                aria-hidden="true"
                className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 12 12"
                  className="h-3 w-3 text-indigo-600"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </div>
        <div className="flex items-center mb-4 w-full justify-between">
          <h2 className="font-bold text-lg">Services</h2>
          {can(`assign services`) && (
            <div
              className="cursor-pointer ml-4 text-blue-500 font-bold items-center flex btn"
              onClick={assignServiceClicked}
            >
              <span>Assign Service</span>
            </div>
          )}
        </div>

        <ul className="flex">
          {assignedServices?.map((service) => {
            return (
              <li key={service.id} className="flex">
                <span className="pill">{service.title}</span>
                {can('remove user services') ? (
                  <span
                    title="Remove Service"
                    className="pill-button"
                    onClick={() => deleteServiceClicked(service)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                ) : (
                  <span className="mr-4"></span>
                )}
              </li>
            )
          })}
        </ul>
      </section>
    </div>
  )
}

export default User
