import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'

function MainTitle({ title, client = null, backButton = false, child = false, discharge = false}) {
  const navigate = useNavigate()
  const { setShowLoader, createInfo} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState({})
  const [dischargeData, setDischargeData] = useState({})
  const axios = useAxiosPrivate()

  const goBack = () => {
    navigate(-1)
  }

  const openDischargeReason = (title) => {
    setModalTitle(`Set Discharge Reason for ${title}`)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (showModal) {
      return <DischargeReasonElement />
    }

    return null
  }

  const DischargeReasonElement = () => {
    const submitDischargeReason = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.post(
          '/notifications/create',
          {
            title: 'Request to Discharge',
            body: dischargeData.reason,
            meta: {
              client_id: client.id,
              client_reference: client.reference,
              claim_reference: client.claim_reference,
            },
            type: 'Admin',
            area: 'Client',
          }
        )
        createInfo('success', `Client discharge request sent successfully`)
        setShowLoader(false)
      } catch (error) {
        createInfo('error', `Could not complete client discharge request`)
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={submitDischargeReason}>
        <div className="mb-3">
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Reason <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue={dischargeData.reason}
              onChange={(e) => (dischargeData.reason = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md text-base font-normal"
            />
          </div>
        </div>
          <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Save</button>
            <button
              type="button"
              className="btn red mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const resetModal = (resetNotes = true) => {
    setDischargeData({reason: ''})
    setShowModal(false)
  }

  return (
    <div className="font-bold py-2 mt-2 text-xl w-full border-b border-b-gray-300 mb-4">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <div className='flex item justify-between'>
        <h1 className='flex items-center space-x-2'>
          <span>{title}</span> 
          {client?.status && (
              <span className={`status ${client.status.replace(/\s+/g, '-').toLowerCase()}`}>
                {client.status}
              </span>
          )}
          {(child === true || child === 1) ? (
            <span className='text-xs font-medium px-4 py-1.5 bg-pink-600 rounded-full text-white'>Child</span>
          ) : null}
        </h1>
        {(discharge === true) ? (
           <div  onClick={() => openDischargeReason(title)} className='rounded-lg text-sm bg-red-700 text-white shadow-md px-4 py-2 hover:bg-red-800'>
            <button>Request Discharge</button>
          </div>
        ) : null}
        
      </div>

      {backButton && (
        <span
          className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
          <span className="text-sm ml-2">Go Back</span>
        </span>
      )}
    </div>
  )
}

export default MainTitle
