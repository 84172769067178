import useAxiosPrivate from "../hooks/useAxiosPrivate"
import useAuth from "./useAuth"

const useLogout = () => {
  const { setAuth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const logout = async () => {
    setAuth({})
    localStorage.removeItem("token")
    localStorage.removeItem("login")
    try {
      await axiosPrivate.get("/logout")
    } catch (error) {
      console.log(error)
    }
  }

  return logout
}

export default useLogout
