import { useState, useEffect } from 'react'
import MainTitle from '../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import MyClinicNav from '../../components/MyClinicNav'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import TuiCalendar from '../../components/TuiCalendar'

const Availability = () => {
  const { auth } = useAuth()
  const axios = useAxiosPrivate()
  const [clinicData, setClinicData] = useState({ name: '' })
  const { setShowLoader } = useAuth()

  const [currentEvents, setCurrentEvents] = useState([])

  const getClinicById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/companies/by-user/${auth.user.id}`)
      await setClinicData(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  useEffect(() => {
    getClinicById()
  }, [])

  const loadCalendarEvents = async (from, to) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/companies/${clinicData.id}/sessions/${from}/${to}`
      )
      setCurrentEvents(response.data.result)
    } catch (error) {}
    // }
    setShowLoader(false)
  }

  return (
    <section>
      <MainTitle title={`Clinic: ${clinicData.name}`} backButton />
      <MyClinicNav active="bookings" clinicId={clinicData.id} />

      {clinicData.id && (
        <TuiCalendar
          events={currentEvents}
          loadEvents={loadCalendarEvents}
          calendarType="bookings"
        />
      )}
    </section>
  )
}

export default Availability
