/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import {
  faCircleExclamation,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import axios from '../api/axios'

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const RESET_URL = '/reset-password'

const ResetPassword = () => {
  let [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const passwordRef = useRef()
  const errRef = useRef()

  const [pwd, setPwd] = useState('')
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')
  const [error, setError] = useState(false)

  const token = searchParams.get('token')

  useEffect(() => {
    if (!token) {
      navigate('/forgot-password', { replace: true })
    }
  }, [token])

  useEffect(() => {
    passwordRef.current.focus()
  }, [])

  useEffect(() => {
    const result = PWD_REGEX.test(pwd)
    setValidPwd(result)
    const match = pwd === matchPwd
    setValidMatch(match)
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('')
  }, [pwd, matchPwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = PWD_REGEX.test(pwd)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      const response = await axios.post(RESET_URL, {
        token,
        password: pwd,
        password_confirmation: matchPwd,
      })
      setPwd('')

      if (response.data.result.invalid_token) {
        setError(true)
      } else {
        navigate('/', { replace: true })
      }
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not Reset Password.')
      }
      errRef.current.focus()
    }
  }

  return (
    <div className="py-8 px-8 w-full" style={{ maxWidth: 500, minHeight: 200 }}>
      {error ? (
        <div>
          <h1 className="text-2xl text-wba-primary font-bold text-center mb-2">
            Token Expired
          </h1>
          <p className="text-center">
            We could not reset your password as the token used has expired.
            Please go to{' '}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => navigate('/forgot-password', { replace: true })}
            >
              Forgot Password
            </span>{' '}
            and try again.
          </p>
        </div>
      ) : (
        <div>
          <h1 className="text-3xl text-wba-primary font-bold text-center mb-6">
            Reset Password
          </h1>
          <p
            ref={errRef}
            className={errMsg ? 'errmsg' : 'offscreen'}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="input-wrap">
              <input
                type="password"
                id="pwd"
                ref={passwordRef}
                onChange={(e) => setPwd(e.target.value)}
                required
                aria-invalid={validPwd ? 'false' : 'true'}
                aria-describedby="pwdnote"
                placeholder="Password"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                className="field"
              />
              <label htmlFor="pwd">Password</label>
              <span
                className={validPwd ? 'valid input-check' : 'hide input-check'}
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span
                className={
                  validPwd || !pwd ? 'hide input-check' : 'invalid input-check'
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <p
                id="pwdnote"
                className={pwdFocus && !validPwd ? 'instructions' : 'offscreen'}
              >
                <FontAwesomeIcon icon={faCircleExclamation} />
                8 to 24 characters. <br />
                Must include uppercase and lowercase letters, a number and a
                special character. <br />
                Allowed special characters:
                <span aria-label="exclamation mark"> !</span>
                <span aria-label="at symbol"> @</span>
                <span aria-label="hashtag"> #</span>
                <span aria-label="dollar sign"> $</span>
                <span aria-label="percent"> %</span>
              </p>
            </div>

            <div className="input-wrap">
              <input
                type="password"
                id="confirm_pwd"
                onChange={(e) => setMatchPwd(e.target.value)}
                required
                aria-invalid={validMatch ? 'false' : 'true'}
                aria-describedby="confirmnote"
                placeholder="Confirm Password"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                className="field"
              />
              <label htmlFor="confirm_pwd">Confirm Password</label>
              <span
                className={
                  validMatch && matchPwd
                    ? 'valid input-check'
                    : 'hide input-check'
                }
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span
                className={
                  validMatch || !matchPwd
                    ? 'hide input-check'
                    : 'invalid input-check'
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
              <p
                id="confirmnote"
                className={
                  matchFocus && matchPwd && !validMatch
                    ? 'instructions'
                    : 'offscreen'
                }
              >
                <FontAwesomeIcon icon={faCircleExclamation} />
                Must match the first password input.
              </p>
            </div>

            <div className="input-wrap">
              <input
                type="submit"
                disabled={!validPwd || !validMatch ? true : false}
                value="Reset Password"
              />
            </div>
          </form>

          <div className="mt-4 text-wba-primary text-xs flex justify-center">
            <Link to="/login" className="hover:text-blue-800">
              Already have an account? Login here
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
