/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'

import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faTrashCan,
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { data } from 'autoprefixer'

function Clients() {
  const axios = useAxiosPrivate()
  let totalPractitioners = 0
  const { setShowLoader, showLoader, can, createInfo } = useAuth()

  const [clients, setClients] = useState([])
  const [deleteClient, setDeleteClient] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [clientStatuses, setClientStatuses] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')

  const [originalRecords, setOriginalRecords] = useState([])
  const [columnClickCounts, setColumnClickCounts] = useState({})
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })

  useEffect(() => {
    init()
    fetchClientStatuses();
  }, [])

  const init = async () => {
    setShowLoader(true)
    if (!showLoader) {
      getClients()
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1
      return 0
    })
  }

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />
      } else {
        return <FontAwesomeIcon icon={faSortDown} />
      }
    }
    return <FontAwesomeIcon icon={faSort} />
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts }
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1
    } else {
      newColumnClickCounts[key]++
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      })
      newColumnClickCounts[key] = 0
      setCurrentRecords(
        originalRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    } else {
      let direction =
        sortConfig.direction === 'ascending' ? 'descending' : 'ascending'
      setSortConfig({ key, direction })

      const sortedRecords = sortArray(originalRecords, key, direction)
      setCurrentRecords(
        sortedRecords.slice(indexOfFirstRecord, indexOfLastRecord)
      )
    }
    setColumnClickCounts(newColumnClickCounts)
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * recordsPerPage - recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = (newPage - 1) * recordsPerPage
      const indexLast = newPage * recordsPerPage

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)

      const sortedRecords = sortArray(
        originalRecords,
        sortConfig.key,
        sortConfig.direction
      )
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast))
    }
  }
  
  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    setDropdownValue(e.target.value);
  }

  const fetchClientStatuses = async () => {
    try {
      const response = await axios.get('/client-statuses/all');
      const statusTitles = response.data.result.map(status => status.title);
      const sortedStatuses = statusTitles.sort((a, b) => a.localeCompare(b));
      setClientStatuses(sortedStatuses);
    } catch (error) {
      createInfo('Error fetching client statuses')
    }
  };

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    setSearch(searchValue)
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let filteredClients = searchString.length > 0 ? clients.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.email.toLowerCase().match(searchString) ||
          e.reference.toLowerCase().match(searchString) ||
          e.title.toLowerCase().match(searchString) ||
          e.created_at.toLowerCase().match(searchString)
        )
      }) : clients;
      if (statusFilter) {
        filteredClients = filteredClients.filter(client => client.title.toLowerCase() === statusFilter);
      }
      setFilteredRecords(filteredClients);
      setCurrentRecords(filteredClients.slice(indexOfFirstRecord, indexOfLastRecord));
  setNumberOfPages(Math.ceil(filteredClients.length / recordsPerPage));
    } else {
      setFilteredRecords(clients)
      setCurrentRecords(clients.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(clients.length / recordsPerPage))
    }

    setSortConfig({
      key: null,
      direction: 'descending',
    })
  }

  useEffect(() => {
    getClients();
  }, [statusFilter]);

  const getClients = async () => {
    try {
      const response = await axios.get('/clients/all')
      let filteredClients = response.data.result;
      if (statusFilter) {
        filteredClients = filteredClients.filter(client => client.title.toLowerCase() === statusFilter);
      }
      setClients(filteredClients);
      setOriginalRecords(filteredClients);
      setFilteredRecords(filteredClients);
      setCurrentRecords(filteredClients.slice(indexOfFirstRecord, indexOfLastRecord));
      setNumberOfPages(Math.ceil(filteredClients.length / recordsPerPage));
    } catch (error) {}
    setShowLoader(false)
  }

  const deleteClientClicked = (client) => {
    setDeleteClient(client)
    setModalTitle(`Delete client: ${client.name}`)
    setShowModal(true)
  }

  const resetModal = (resetClients = true) => {
    setDeleteClient({})
    setShowModal(false)
    if (resetClients) {
      setClients(clients)
    }
  }

  const ModalBody = () => {
    if (deleteClient.id) {
      return <DeleteClientElement />
    }
  }

  const DeleteClientElement = () => {
    const [sendDischargeEmail, setSendDischargeEmail] = useState(false)
    const handleCheckboxChange = (e) => {
      setSendDischargeEmail(e.target.checked)
    }

    const deleteClientFn = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        await axios.post('/clients/delete/' + deleteClient.id, {
          sendDischargeEmail: sendDischargeEmail,
        })
        createInfo('error', `Deleted Client: ${deleteClient.name}`)

        setCurrentRecords(
          currentRecords.filter((client) => {
            return client.id !== deleteClient.id
          })
        )
        setClients(
          clients.filter((client) => {
            return client.id !== deleteClient.id
          })
        )

        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteClientFn}>
        <div className="mb-3">
          <p className="text-lg font-bold mt-8 mb-4 text-center">
            You are deleting client {deleteClient.name}
          </p>
          <p className="text-center mt-6">
            Would you like to send a confirmation discharge email?
          </p>
          <div className="flex items-center ps-4 mb-4 mt-2 border border-gray-200 rounded dark:border-gray-700">
            <input
              id="bordered-checkbox-1"
              type="checkbox"
              value=""
              name="bordered-checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              checked={sendDischargeEmail}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="bordered-checkbox-1"
              className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Yes, send discharge confirmation
            </label>
          </div>
          
          <div className="flex w-full mt-8">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Client</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  return (
    <section>
      <MainTitle title="Clients" />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {can('create clients') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="new">
                <CardButton icon={faPlus} title="Create New Client" />
              </NavLink>
            </li>
          )}
          <li className="rounded-md bg-white shadow h-20 w-1/4 px-4 mb-6 flex flex-col justify-center mr-3">
            <h2 className="font-bold">Total Clients</h2>
            <p className="text-3xl font-black">{clients.length}</p>
          </li>
        </ul>
      </section>

      <div className='flex justify-between '>
        <div className="py-4">
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
            </div>
            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Clients"
            />
          </div>
        </div>
        <div className="py-4">
          <select
            onChange={handleStatusChange}
            value={dropdownValue}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-md mr-2"
          >
            <option value="">Filter by Status</option>
            {clientStatuses.map((status, index) => (
              <option key={index} value={status.toLowerCase()}>
                {status}
              </option>
            ))}
          </select>
          {isFilterApplied() && (
            <button
              onClick={resetFilters}
              className="bg-red-500 text-white rounded-lg px-4 py-2 ml-4"
            >
              Reset Filters
            </button>
          )}
        </div>
      </div>


      <table className="table-main">
        <thead>
          <tr>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('id')}
            >
              ID {renderSortArrow('id')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('name')}
            >
              Name {renderSortArrow('name')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('email')}
            >
              Email {renderSortArrow('email')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('reference')}
            >
              Reference {renderSortArrow('reference')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('title')}
            >
              Status {renderSortArrow('title')}
            </th>
            <th
              className="cursor-pointer"
              scope="col"
              onClick={() => handleSort('created_at')}
            >
              Created At {renderSortArrow('created_at')}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="table-main">
          {currentRecords.length > 0 ? (
            currentRecords.map((client) => {
              return (
                <tr key={client.id}>
                  <td>{client.id}</td>
                  <td>{client.name}</td>
                  <td>{client.email}</td>
                  <td>{client.reference}</td>
                  <td>
                    <div
                      className={`status ${client.title
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`}
                    >
                      {client.title}
                    </div>
                  </td>
                  <td>{moment(client.created_at).format('DD/MM/YYYY')}</td>
                  <td>
                    <div className="flex justify-center space-x-2">
                      <Link to={`/clients/${client.id}`}>
                        <span className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 hover:bg-blue-500 cursor-pointer">
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                      </Link>

                      <span
                        onClick={() => deleteClientClicked(client)}
                        className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 hover:bg-red-500 cursor-pointer"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <p className="no-records">No clients found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        next={nextPage}
        prev={previousPage}
        first={indexOfFirstRecord}
        last={indexOfLastRecord}
        total={filteredRecords.length}
      />
    </section>
  )
}

export default Clients
