/* eslint-disable */
import { useState, useEffect, useRef } from "react"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import MainTitle from "../../../components/MainTitle"
import { Link, useParams } from "react-router-dom"
import useAuth from "../../../hooks/useAuth"
import ClinicProfile from "../../../components/ClinicProfile"
import ClinicServices from "../../../components/ClinicServices"
import ClinicPractitioners from "../../../components/ClinicPractitioners"
import ClinicQuestionnaires from "../../../components/ClinicQuestionnaires"
import ClinicClients from "../../../components/ClinicClients"
import ClinicNav from "../../../components/ClinicNav"

const Clinic = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const [clinicData, setClinicData] = useState({ name: "" })
  const { setShowLoader } = useAuth()
  const [activeComponent, setActiveComponent] = useState("services")

  const getClinicById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/companies/${id}`)
      await setClinicData(response.data.result)
      setShowLoader(false)
    } catch (error) {}
  }

  useEffect(() => {
    getClinicById(id)
  }, [])

  return (
    <div>
      <MainTitle title={`Clinic: ${clinicData.name}`} backButton />
      <ClinicNav active="dashboard" clinicId={clinicData.id} />

      {clinicData.id && (
        <section>
          <ClinicProfile clinic={clinicData} />

          <section className="relative w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <div>
              <ul className=" flex flex-col md:flex-row flex-wrap list-none mb-4 px-0">
                <li className="nav-item w-1/4" role="presentation">
                  <span
                    className={`tab-btn-underline mr-8 ${
                      activeComponent == "services" ? "active" : ""
                    }`}
                    onClick={() => setActiveComponent("services")}
                  >
                    Services
                  </span>
                </li>
                <li className="nav-item w-1/4" role="presentation">
                  <span
                    className={`tab-btn-underline ${
                      activeComponent == "practitioners" ? "active" : ""
                    }`}
                    onClick={() => setActiveComponent("practitioners")}
                  >
                    Practitioners
                  </span>
                </li>
                <li className="nav-item w-1/4" role="presentation">
                  <span
                    className={`tab-btn-underline ml-8 ${
                      activeComponent == "clients" ? "active" : ""
                    }`}
                    onClick={() => setActiveComponent("clients")}
                  >
                    Clients
                  </span>
                </li>
                <li className="nav-item w-1/4" role="presentation">
                  <span
                    className={`tab-btn-underline ml-8 ${
                      activeComponent == "questionnaires" ? "active" : ""
                    }`}
                    onClick={() => setActiveComponent("questionnaires")}
                  >
                    Questionnaires
                  </span>
                </li>
              </ul>
            </div>

            <div
              className="w-full"
              style={{
                display: activeComponent === "services" ? "flex" : "none",
              }}
            >
              <ClinicServices clinicId={id} />
            </div>
            <div
              className="w-full"
              style={{
                display: activeComponent === "questionnaires" ? "flex" : "none",
              }}
            >
              <ClinicQuestionnaires questionnairesData={clinicData.questionnaires} />
            </div>
            <div
              className="w-full"
              style={{
                display: activeComponent === "practitioners" ? "flex" : "none",
              }}
            >
              <ClinicPractitioners clinicId={id} />
            </div>
            <div
              className="w-full"
              style={{
                display: activeComponent === "clients" ? "flex" : "none",
              }}
            >
              <ClinicClients clinicId={id} />
            </div>
          </section>
        </section>
      )}
    </div>
  )
}

export default Clinic
