import MainTitle from '../../components/MainTitle'
import Payslip from '../../components/Payslip'

import { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'

function Accounts() {
  const [practitioners, setPractitioners] = useState([])

  const { setShowLoader } = useAuth()
  const axios = useAxiosPrivate()

  return (
    <section>
      <MainTitle title="Accounts" />

      <ul className="flex just-between flex-wrap">
        <li className="w-1/4 mb-6 mx-3">
          <NavLink to="payslips">
            <CardButton icon={faFileInvoiceDollar} title="Payslips" />
          </NavLink>
        </li>
      </ul>
    </section>
  )
}

export default Accounts
