/* eslint-disable */
import MainTitle from '../../../components/MainTitle'

import { useState, useEffect, useRef } from 'react'

import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPencil,
  faSave,
  faTimes,
  faTrashCan,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../../components/Modal'
import CardButton from '../../../components/CardButton'

function Permissions() {
  const axios = useAxiosPrivate()

  const { setShowLoader, createInfo } = useAuth()

  const [roles, setRoles] = useState([])
  const [permissions, setPermissions] = useState([])
  const [newRole, setNewRole] = useState(false)
  const [createRole, setCreateRole] = useState({})
  const [createPermission, setCreatePermission] = useState({})
  const [newPermission, setNewPermission] = useState(false)
  const [newCategory, setNewCategory] = useState({})
  const [editRole, setEditRole] = useState('')
  const [editRoleName, setEditRoleName] = useState('')

  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')

  const errRoleRef = useRef()
  const [errRoleMsg, setErrRoleMsg] = useState('')

  const [deleteRole, setDeleteRole] = useState({})

  const [deletePermission, setDeletePermission] = useState({})

  const [showModal, setShowModal] = useState(false)

  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    await Promise.all([getRoles(), getPermissions()])
    setShowLoader(false)
  }

  const getRoles = async () => {
    try {
      const response = await axios.get('/roles/all')
      refreshRoles(response)
    } catch (error) {}
  }

  const getPermissions = async () => {
    try {
      const response = await axios.get('/permissions/all')
      sortPermissions(response.data.result)
    } catch (error) {}
  }

  const sortPermissions = (permissions) => {
    const categories = []
    const data = []
    permissions.forEach((permission) => {
      if (!categories.includes(permission.category)) {
        categories.push(permission.category)
        data.push({ category: permission.category, permissions: [] })
      }
      data.forEach((group) => {
        if (group.category === permission.category) {
          group.permissions.push({ id: permission.id, name: permission.name })
        }
      })
    })

    setPermissions(data)
  }

  const ShowRoles = ({ permission }) => {
    return roles.map((role) => {
      return (
        <td key={role.id} className="border-r border-r-slate-300">
          <input
            type="checkbox"
            defaultChecked={role.permissions.includes(permission.name)}
            onChange={() => updateRolePermission(role, permission)}
          />
        </td>
      )
    })
  }

  const updateRolePermission = async (role, permission) => {
    if (role.permissions.indexOf(permission.name) >= 0) {
      role.permissions.splice(role.permissions.indexOf(permission.name), 1)
    } else {
      role.permissions.push(permission.name)
    }
  }

  const refreshRoles = (response) => {
    response.data.result.map((role) => {
      return (role.permissions = role.permissions.map((permission) => {
        return permission.name
      }))
    })
    setRoles(response.data.result)
  }

  const updatePermissions = async () => {
    setShowLoader(true)
    try {
      const response = await axios.post('/roles/update', roles)
      createInfo('info', 'Perrmissions have been updated')
      refreshRoles(response)
    } catch (error) {}
    setShowLoader(false)
  }

  const createRoleClicked = () => {
    setNewRole(true)
    setCreateRole({})
    setModalTitle(`Add Role`)
    setShowModal(true)
  }

  const createPermissionClicked = () => {
    setNewPermission(true)
    setCreatePermission({})
    setModalTitle(`Add Permission`)
    setShowModal(true)
  }

  const resetModal = () => {
    setDeleteRole({})
    setDeletePermission({})
    setNewRole(false)
    setNewPermission(false)
    setCreateRole({})
    setNewCategory({})
    setCreatePermission({})
    setShowModal(false)
    setModalTitle('')
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const updateRole = async (role) => {
    setShowLoader(true)
    try {
      const response = await axios.post('/roles/update/' + role.id, {
        name: editRoleName,
      })
      createInfo('info', `Updated Role: ${editRoleName}`)
      setEditRole('')
      setEditRoleName('')
      refreshRoles(response)
    } catch (error) {
      console.log(error)
    }
    setShowLoader(false)
  }

  const deleteRoleClicked = (role) => {
    setModalTitle('Delete Role: ' + role.name)
    setDeleteRole({ ...role })
    setShowModal(true)
  }

  const deletePermissionClicked = (permission) => {
    setModalTitle('Delete Permission: ' + permission.name)
    setDeletePermission({ ...permission })
    setShowModal(true)
  }

  const ModalBody = () => {
    if (deleteRole.id) {
      return <DeleteRoleElement />
    }

    if (deletePermission.id) {
      return <DeletePermissionElement />
    }
    if (newPermission) {
      return <CreatePermissionElement />
    }
    if (newRole) {
      return <CreateRoleElement />
    }
  }

  const DeleteRoleElement = () => {
    const removeRole = async () => {
      setShowModal(false)
      setShowLoader(true)
      const response = await axios.get('/roles/delete/' + deleteRole.id)
      createInfo('success', `Deleted Role: ${deleteRole.name}`)
      refreshRoles(response)
      setDeleteRole({})
      setShowLoader(false)
    }
    return (
      <form onSubmit={removeRole} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this role?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete Role</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const DeletePermissionElement = () => {
    const removePermission = async () => {
      setShowModal(false)
      setShowLoader(true)
      const response = await axios.get(
        '/permissions/delete/' + deletePermission.id
      )
      createInfo('error', `Deleted Permission: ${deletePermission.name}`)
      sortPermissions(response.data.result)
      setDeletePermission({})
      setShowLoader(false)
    }
    return (
      <form onSubmit={removePermission} className="justify-center flex">
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this permission?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">
              Delete Permission
            </button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const CreateRoleElement = () => {
    const addRole = async (e) => {
      e.preventDefault()
      setErrRoleMsg('')
      setShowLoader(true)
      try {
        const response = await axios.post('/roles/create', {
          name: createRole.name,
          guard_name: 'api',
        })
        if (response.data.error === false) {
          createInfo('success', `Created Role: ${createRole.name}`)
          refreshRoles(response)
          setNewRole(false)
          resetModal()
        } else {
          setErrRoleMsg(response.data.message)
        }
      } catch (error) {
        if (!error?.response) {
          setErrRoleMsg('No Server Response')
        } else if (error.response?.status === 422) {
          setErrRoleMsg('The email provided already exists.')
        } else {
          setErrRoleMsg('Error Occured. Could not Reset Password.')
        }
        errRef.current.focus()
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={addRole}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Role Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createRole.name}
              required
              type="text"
              name="newRole"
              id="newRole"
              onChange={(e) => (createRole.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create Role</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const CreatePermissionElement = () => {
    const addPermission = async (e) => {
      e.preventDefault()
      setErrMsg('')
      setShowLoader(true)
      try {
        const response = await axios.post('/permissions/create', {
          name: createPermission.name.toLowerCase(),
          category: capitalizeFirstLetter(newCategory.name),
          guard_name: 'api',
        })
        if (response.data.error === false) {
          createInfo(
            'success',
            `Created Permission: ${createPermission.name.toLowerCase()}`
          )
          sortPermissions(response.data.result)
          resetModal()
        } else {
          setErrMsg(response.data.message)
        }
      } catch (error) {
        if (!error?.response) {
          setErrMsg('No Server Response')
        } else if (error.response?.status === 422) {
          setErrMsg('The email provided already exists.')
        } else {
          setErrMsg('Error Occured. Could not Reset Password.')
        }
        errRef.current.focus()
      }
      setShowLoader(false)
    }
    return (
      <form onSubmit={addPermission}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Permission Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={createPermission.name}
              required
              type="text"
              name="newPermission"
              id="newPermission"
              onChange={(e) => (createPermission.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Category Name
          </label>
          <div className="mt-1 relative rounded-md">
            <input
              value={newCategory.name}
              required
              type="text"
              name="newRole"
              id="newRole"
              onChange={(e) => (newCategory.name = e.target.value)}
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Create Permission</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  return (
    <section className="relative">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <MainTitle title="Permissions" backButton />

      {/* CRUD for roles */}
      <ul className="flex just-between flex-wrap cursor-pointer">
        <li className="w-1/4 mb-6 mr-3">
          <div onClick={createRoleClicked}>
            <CardButton icon={faPlus} title="Add Role" />
          </div>
        </li>
        <li className="w-1/4 mb-6 mr-3 cursor-pointer">
          <div onClick={createPermissionClicked}>
            <CardButton icon={faPlus} title="Add Permission" />
          </div>
        </li>
      </ul>
      <div className="w-1/3 mb-4">
        <div>
          <table className="table-main">
            <thead>
              <tr>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role) => {
                return (
                  <tr key={role.id}>
                    <td>
                      {editRole === role.name ? (
                        <input
                          value={editRoleName}
                          onChange={(e) => setEditRoleName(e.target.value)}
                        />
                      ) : (
                        <span>{role.name}</span>
                      )}
                    </td>
                    <td>
                      <div className="flex justify-center">
                        {editRole === role.name ? (
                          <>
                            <span
                              onClick={() => updateRole(role)}
                              className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faSave} />
                            </span>
                            <span
                              onClick={() => {
                                setEditRole('')
                                setEditRoleName('')
                              }}
                              className="flex justify-center items-center bg-orange-300 rounded-md text-orange-800 h-9 w-12 mx-2  hover:bg-orange-400 cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                          </>
                        ) : (
                          <span
                            onClick={() => {
                              setEditRole(role.name)
                              setEditRoleName(role.name)
                            }}
                            className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </span>
                        )}
                        <span
                          className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 mx-2  hover:bg-red-500 cursor-pointer"
                          onClick={() => deleteRoleClicked(role)}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <table className="table-main">
        <thead>
          <tr>
            <th scope="col">Permission</th>
            {roles.map((role) => {
              return (
                <th scope="col" key={role.id}>
                  {role.name}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission) => {
            return (
              <>
                <tr key={permission.id} className="table-sub">
                  <td colSpan={roles.length + 1} className="font-semibold py-2">
                    {permission.category}
                  </td>
                </tr>
                {permission.permissions.map((data) => {
                  return (
                    <tr key={data.id}>
                      <td key={data.id}>
                        {data.name}
                        <span
                          className="justify-center items-center text-red-800 cursor-pointer hidden group-hover:flex"
                          onClick={() => deletePermissionClicked(data)}
                        >
                          <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon>
                        </span>
                      </td>
                      <ShowRoles permission={data} />
                    </tr>
                  )
                })}
              </>
            )
          })}
        </tbody>
      </table>

      <div className="flex justify-end mt-10 w-full">
        <button onClick={updatePermissions} className="btn primary">
          Update Permissions
        </button>
      </div>
    </section>
  )
}

export default Permissions
