import { useState, useEffect } from 'react'
import MainTitle from '../../components/MainTitle'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClinicNav from '../../components/ClinicNav'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import TuiCalendar from '../../components/TuiCalendar'

const Bookings = () => {
  const { id } = useParams()
  const axios = useAxiosPrivate()

  const [clinicData, setClinicData] = useState({ name: '' })
  const { setShowLoader } = useAuth()

  const [currentEvents, setCurrentEvents] = useState([])

  const getClinicById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/companies/${id}`)
      await setClinicData(response.data.result)
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getClinicById(id)
  }, [])

  const loadCalendarEvents = async (from, to) => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/companies/${id}/sessions/${from}/${to}`
      )
      setCurrentEvents(response.data.result)
    } catch (error) {}
    // }
    setShowLoader(false)
  }

  return (
    <section>
      <MainTitle title={`Clinic: ${clinicData.name}`} backButton />
      <ClinicNav active="bookings" clinicId={clinicData.id} />

      {id && (
        <TuiCalendar
          events={currentEvents}
          loadEvents={loadCalendarEvents}
          calendarType="bookings"
        />
      )}
    </section>
  )
}

export default Bookings
