import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from '../api/axios'

const LOGIN_URL = '/login'

const Login = () => {
  const { setAuth } = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const emailRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [email, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(LOGIN_URL, { email, password: pwd })
      // const token = response.data.result.token
      const user = response.data.result.user
      let id = user.id
      // setAuth({ email, pwd, user, token })
      localStorage.setItem('login', true)
      setEmail('')
      setPwd('')
      navigate('/2fa', { state: id })
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 400) {
        setErrMsg('Email or Password Missing.')
      } else if (error.response?.status === 401) {
        setErrMsg('Incorrect Email or Password entered.')
      } else {
        setErrMsg('Error Occured. Could not log you in.')
      }
      errRef.current.focus()
    }
  }

  // const togglePersist = () => {
  //   setPersist((prev) => !prev)
  // }

  // useEffect(() => {
  //   localStorage.setItem('persist', persist)
  // }, [persist])

  return (
    <div>
      <h1 className="text-3xl text-wba-primary font-bold text-center mb-6">
        Login to Your Account
      </h1>
      <p
        ref={errRef}
        className={errMsg ? 'errmsg' : 'offscreen'}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <form onSubmit={handleSubmit}>
        <div className="input-wrap">
          <input
            type="email"
            ref={emailRef}
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
            className="field"
          />
          <label htmlFor="email">Email</label>
        </div>

        <div className="input-wrap">
          <input
            type="password"
            id="pwd"
            onChange={(e) => setPwd(e.target.value)}
            required
            placeholder="Password"
            className="field"
          />
          <label htmlFor="pwd">Password</label>
        </div>

        <div className="mt-4 text-wba-primary text-xs flex justify-end">
          {/* <div className='flex items-center'>
                <label htmlFor='persist' className='mr-2'>
                  Remember Me
                </label>
                <input type='checkbox' id='persist' onChange={togglePersist} placeholder='Remember' className='field' checked={persist} />
              </div> */}

          <Link to="/forgot-password" className="hover:text-blue-800">
            Forgot Password?
          </Link>
        </div>

        <div className="input-wrap">
          <input type="submit" value="Login" />
        </div>
      </form>
    </div>
  )
}

export default Login
