/* eslint-disable */
import MainTitle from '../../components/MainTitle'
import { NavLink } from 'react-router-dom'
import CardButton from '../../components/CardButton'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../../components/Pagination'
import {
  faTrashCan,
  faMagnifyingGlass,
  faEye,
  faPlus,
  faSortDown,
  faSortUp,
  faSort
} from '@fortawesome/free-solid-svg-icons'
import Modal from '../../components/Modal'
import { Link } from 'react-router-dom'

function Clients() {
  const axios = useAxiosPrivate()
  const { setShowLoader, can, createInfo, auth } = useAuth()

  const [clients, setClients] = useState([])
  const [deleteClient, setDeleteClient] = useState({})
  const [showModal, setShowModal] = useState(false)

  const [search, setSearch] = useState('')

  const [currentRecords, setCurrentRecords] = useState([])
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const [modalTitle, setModalTitle] = useState('')

  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const navigate = useNavigate();

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setShowLoader(true)
    getClients()
  }

  const viewClient = (clientId) => {
    navigate(`/clients/${clientId}`)
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = clients.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString) ||
          e.email.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(clients)
      setCurrentRecords(clients.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(clients.length / recordsPerPage))
    }
  }

  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }
  
    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });
  
      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };
  
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;
  
      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
  
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const getClients = async () => {
    try {
      const response = await axios.get(`/clients/all/by-user/${auth.user.id}`)
      await setClients(response.data.result)
      await setFilteredRecords(response.data.result)
      await setOriginalRecords(response.data.result);
      await setCurrentRecords(response.data.result.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ))
      await setNumberOfPages(
        Math.ceil(response.data.result.length / recordsPerPage)
      );
    } catch (error) {}
    setShowLoader(false)
  }

  const resetModal = (resetClients = true) => {
    setDeleteClient({})
    setShowModal(false)
    if (resetClients) {
      setClients(clients)
    }
  }

  return (
    <section>
      <MainTitle title="My Clients" />

      <section>
        {showModal && (
          <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
        )}
        <ul className="flex just-between flex-wrap">
          {can('create clients') && (
            <li className="w-1/4 mb-6 mr-3">
              <NavLink to="new">
                <CardButton icon={faPlus} title="Create New Client" />
              </NavLink>
            </li>
          )}
          <li className="rounded-md bg-white shadow h-20 w-1/4 px-4 mb-6 flex flex-col justify-center mr-3">
            <h2 className="font-bold">Total Clients</h2>
            <p className="text-3xl font-black">{clients.length}</p>
          </li>
        </ul>
      </section>

      <div className="py-4">
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch} />
          </div>
          <input
            type="text"
            id="table-search"
            onChange={handleSearch}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Clients"
          />
        </div>
      </div>

      <table className="table-main">
            <thead>
              <tr>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('name')}>Name {renderSortArrow('name')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('email')}>Email {renderSortArrow('email')}</th>
                {can('view client status') && <th className='cursor-pointer' scope="col" onClick={() => handleSort('status')}>Status {renderSortArrow('status')} </th>}
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('reference')}>Reference {renderSortArrow('reference')}</th>
                <th className='cursor-pointer' scope="col" onClick={() => handleSort('created_at')}>Created At {renderSortArrow('created_at')}</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords?.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      {can('view client status') && (
                        <td>
                          <div
                            className={`status ${user.status.replace(/\s+/g, '-').toLowerCase()}`}
                          >
                            {user.status}
                          </div>
                        </td>
                      )}
                      <td>{user.reference}</td>
                      <td>{moment(user.claim_created).format('DD/MM/YYYY')}</td>
                      {can('view clinic clients') && (
                        <td>
                          <div className="flex justify-center">
                          <span
                          title="View Client"
                          onClick={() => viewClient(user.id)}
                          className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                    </div>
                        </td>
                      )}
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={6}>
                    <p className="no-records">No clients found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
    </section>
  )
}

export default Clients
