import moment from 'moment'
import { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import Pagination from '../components/Pagination'

function PayslipHistory({ data = [] }) {
  const { can } = useAuth()
  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)

  const viewPayslip = (filename) => {
    window
      .open(
        process.env.REACT_APP_URL + '/payslip/download/' + filename,
        '_blank'
      )
      .focus()
  }

  useEffect(() => {
    if (data) {
      setFilteredRecords(data)
      setCurrentRecords(data.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(data.length / recordsPerPage))
    }
  }, [data])

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  return (
    <div className="w-full py-4">
      <span className="text-lg font-semibold"> Payslip History</span>
      <div className="overflow-hidden rounded-lg mt-4">
        <table className="table-main">
          <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
            <tr>
              <th scope="col" className="px-6 py-4">
                Date Created
              </th>
              <th scope="col" className="px-6 py-4">
                Payslip Start Date
              </th>
              <th scope="col" className="px-6 py-4">
                Payslip End Date
              </th>
              <th scope="col" className="px-6 py-4">
                Adjustments Total
              </th>
              <th scope="col" className="px-6 py-4">
                Payslip Total
              </th>
              {can('view payslip') && (
                <th scope="col" className="px-6 py-4">
                  View Payslip
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 ? (
              currentRecords.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                  >
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {moment(item.start_time).format('DD/MM/YYYY')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      {moment(item.end_time).format('DD/MM/YYYY')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      &pound;{item.adjustment_total}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      &pound;{item.payslip_total}
                    </td>
                    {can('view payslip') && (
                      <td className="whitespace-nowrap px-6 py-4 font-medium flex justify-center">
                        <span
                          title="Delete Adjustment"
                          onClick={() => viewPayslip(item.filename)}
                          className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12 mx-2  hover:bg-blue-500 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </span>
                      </td>
                    )}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <p className="no-records">No Payslip History found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          next={nextPage}
          prev={previousPage}
          first={indexOfFirstRecord}
          last={indexOfLastRecord}
          total={filteredRecords.length}
        />
      </div>
    </div>
  )
}

export default PayslipHistory
