/* eslint-disable */
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAuth from '../../hooks/useAuth'
import { PhoneNumberUtil } from 'google-libphonenumber'
import {
  faCircleExclamation,
  faCheck,
  faTimes,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserNav from '../../components/UserNav'

import MainTitle from '../../components/MainTitle'
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom'
import Modal from '../../components/Modal'

const Specialities = () => {
  //Auth
  const { setShowLoader, can, createInfo, auth, setAuth } = useAuth()
  const axios = useAxiosPrivate()
  const [userData, setUserData] = useState({})

  const [specialities, setSpecialities] = useState([])
  const [assignedSpecialities, setAssignedSpecialities] = useState([])

  const { id } = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const getUserById = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/users/${id}`)
      if (response.data.result) {
        setUserData(response.data.result)
        setAssignedSpecialities(response.data.result.specialities)
        getSpecialities()
      } else {
        navigate(from, { replace: true })
      }
      setShowLoader(false)
    } catch (error) {
      setShowLoader(false)
    }
  }

  const getSpecialities = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/specialities/all`)
      if (response.data.result) {
        setSpecialities(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  useEffect(() => {
    getUserById(id)
  }, [id])

  const updateSpeciality = (speciality) => {
    let tempArr = [...assignedSpecialities]
    let updated = false
    tempArr.forEach((assigned, index) => {
      if (assigned.title == speciality.title) {
        updated = true
        tempArr.splice(index, 1)
      }
    })
    if (!updated) {
      tempArr.push(speciality)
    }

    setAssignedSpecialities(tempArr)
  }

  const saveSpecialities = async () => {
    let data = []
    assignedSpecialities.forEach((speciality) => {
      data.push(speciality.id)
    })

    try {
      setShowLoader(true)
      const response = await axios.post(
        `/users/${id}/specialities/update`,
        data
      )
      if (!response.data.error) {
        createInfo('success', `Specialities updated`)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  return (
    <section>
      <MainTitle title="Specialities" />

      <UserNav active="specialities" userId={userData.id} />

      <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg mb-4">Key Skills</h2>
          <button className="btn primary" onClick={saveSpecialities}>
            Update
          </button>
        </div>

        <div className="flex">
          <form onSubmit={saveSpecialities}>
            {specialities.length > 0 ? (
              specialities.map((speciality, index) => {
                return (
                  <div className="items-center flex mb-2" key={index}>
                    <input
                      type="checkbox"
                      id={`speciality_${index}`}
                      defaultChecked={assignedSpecialities.some(
                        (e) => e.title === speciality.title
                      )}
                      onChange={() => updateSpeciality(speciality)}
                    />
                    <label
                      htmlFor={`speciality_${index}`}
                      className="ml-2 font-medium text-gray-900 dark:text-gray-300"
                    >
                      {speciality.title}
                    </label>
                  </div>
                )
              })
            ) : (
              <p>No Specialities found</p>
            )}
          </form>
        </div>
      </section>
    </section>
  )
}

export default Specialities
