import MainTitle from '../../components/MainTitle'
import TuiCalendar from '../../components/TuiCalendar'

import { useState } from 'react'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

function CalendarPage() {
  const axios = useAxiosPrivate()

  const [calendarType, setCalendarType] = useState('bookings')
  const [currentEvents, setCurrentEvents] = useState([])

  const { auth, setShowLoader } = useAuth()

  const loadCalendarEvents = async (from, to) => {
    setShowLoader(true)
    if (calendarType === 'bookings') {
      try {
        const response = await axios.get(
          `/users/${auth.user.id}/sessions/${from}/${to}`
        )
        setCurrentEvents(response.data.result)
      } catch (error) {}
    }
    if (calendarType === 'availability') {
      try {
        const response = await axios.get(
          `/users/${auth.user.id}/availability/${from}/${to}`
        )
        setCurrentEvents(response.data.result)
      } catch (error) {}
    }
    setShowLoader(false)
  }

  return (
    <section>
      <MainTitle title="Calendar" />

      <div className="bg-white rounded-md shadow-md py-3 px-4 mb-4">
        <ul className="flex flex-col md:flex-row flex-wrap list-none px-0">
          <li
            className={`tab-btn ${calendarType === 'bookings' ? 'active' : ''}`}
            onClick={() => {
              setCalendarType('bookings')
            }}
          >
            Bookings
          </li>
          <li
            className={`tab-btn ${
              calendarType === 'availability' ? 'active' : ''
            }`}
            onClick={() => {
              setCalendarType('availability')
            }}
          >
            Availability
          </li>
        </ul>
      </div>

      <TuiCalendar
        events={currentEvents}
        loadEvents={loadCalendarEvents}
        calendarType={calendarType}
        userId={auth.user.id}
      />
    </section>
  )
}

export default CalendarPage
