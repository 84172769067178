import React from 'react'
import { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '../components/Pagination'
import Modal from '../components/Modal'
import {
  faMagnifyingGlass,
  faEye,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

function ClaimSessions({ sessions }) {
  // Auth
  const { clientId, claimId } = useParams()
  const { setShowLoader, can, createInfo, admin } = useAuth()
  const axios = useAxiosPrivate()
  const navigate = useNavigate()

  const [currentRecords, setCurrentRecords] = useState([])

  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  // No of Records to be displayed on each page
  const [recordsPerPage] = useState(10)

  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [selectedCancelReason, setSelectedCancelReason] = useState('0')
  const [sessionToCancel, setSessionToCancel] = useState(false)
  const [cancelReasons, setCancelReasons] = useState([])
  const [selctedSession, setSelectedSession] = useState([])
  //Modal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    if (sessions) {
      setFilteredRecords(sessions)
      setCurrentRecords(sessions)
      getCancelReasons()
    } else {
      navigate(`/clients/${clientId}`)
    }
  }, [sessions])

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = sessions.filter((e) => {
        return (
          e.user.name.toLowerCase().match(searchString) ||
          e.service.title.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(sessions)
      setCurrentRecords(sessions.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(sessions.length / recordsPerPage))
    }
  }

  const getCancelReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/cancel-reasons/all`)
      if (response.data.result) {
        await setCancelReasons(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const cancelSessionClicked = (id) => {
    setModalTitle(`Cancel Session`)
    setSessionToCancel(true)
    setSelectedSession(id)
    setShowModal(true)
  }

  const ModalBody = () => {
    if (sessionToCancel) {
      return <CancelSessionElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
    setSessionToCancel(false)
    setSelectedCancelReason('0')
  }

  const CancelSessionElement = () => {
    const cancelSession = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      let chosenReason = JSON.parse(selectedCancelReason)
      let data = {}
      data['cancel_reason_id'] = chosenReason.id
      try {
        const response = await axios.post(
          `/sessions/cancel/${selctedSession}`,
          data
        )
        if (!response.data.error) {
          setShowLoader(false)
          resetModal()
          createInfo('success', `Session has been canclled`)
          window.location.reload()
        }
      } catch (error) {
        console.log(error)
      }

      setShowLoader(false)
    }

    return (
      <form onSubmit={cancelSession}>
        <div className="flex justify-center mb-4 p-2">
          <h3 className="text-lg font-bold">
            Are you sure you want to cancel this session?
          </h3>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Please select a reason for cancelling this session
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              defaultValue={selectedCancelReason || '0'}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              id="cancel-reason"
              onChange={(e) => setSelectedCancelReason(e.target.value)}
            >
              <option disabled value="0">
                Select a cancel reason
              </option>
              {cancelReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={JSON.stringify(reason)}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
          {selectedCancelReason && selectedCancelReason !== '0' && (
            <div className="flex content-center mt-4">
              <span className="content-center text-red-600">
                {(JSON.parse(selectedCancelReason).id === 1 || JSON.parse(selectedCancelReason).id === 2)
                  ? 'Make sure you let the client know, they will not be able to get this session back.'
                  : 'This session will not be taken from the client’s allowance.'}
              </span>
            </div>
          )}
        </div>

        <div className="flex mt-2 w-full">
          <button
            type="submit"
            className={` mt-4 mr-4 w-1/2 ${
              !selectedCancelReason || selectedCancelReason === '0'
                ? 'cursor-not-allowed text-sm inline-flex justify-center items-center bg-gray-500 border-gray-500 text-white font-bold py-2.5 px-4 border rounded'
                : 'btn blue'
            }`}
            disabled={!selectedCancelReason || selectedCancelReason === '0'}
          >
            Yes, cancel this session
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            No
          </button>
        </div>
      </form>
    )
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const viewSession = (sessionId) => {
    navigate(`/clients/${clientId}/claims/${claimId}/sessions/${sessionId}`)
  }

  return (
    <section className="w-full">
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <h2 className="font-medium text-lg mb-4">Sessions</h2>
      <div>
        <div className="pb-4 flex justify-between">
          <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={handleSearch}
              />
            </div>
            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search Sessions"
            />
          </div>
        </div>

        <table className="table-main">
          <thead>
            <tr>
              <th scope="col">Session Type</th>
              <th scope="col">Service</th>
              <th scope="col">Practitioner</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 ? (
              currentRecords?.map((session) => {
                return (
                  <tr key={session.id}>
                    <td>
                      {session.type_id === 2 ? 'Assessment' : 'Appointment'} {' '}
                      { session.video_session === 1 && (
                          <span className=" text-blue-600 font-semibold">
                            (Video Session)
                          </span> 
                        )}
                    </td>
                    <td>{session.service.title}</td>
                    <td>{session.user.name}</td>
                    <td>
                      {moment(session.start_time).format('DD/MM/YYYY HH:mm')}
                    </td>
                    <td>
                      <div className={`status ${session.status.toLowerCase()}`}>
                        {session.status}
                      </div>
                    </td>
                    <td className="w-10">
                      <div className="flex items-center space-x-2 ">
                        <span
                          title="View Session"
                          onClick={() => viewSession(session.id)}
                          className="flex justify-center items-center bg-blue-400 rounded-md text-blue-800 h-9 w-12  hover:bg-blue-500 cursor-pointer"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                        {can('cancel session') &&
                          session.status !== 'Cancelled' && (
                            <span
                              title="Cancel Session"
                              onClick={() => cancelSessionClicked(session.id)}
                              className="flex justify-center items-center bg-red-400 rounded-md text-red-800 h-9 w-12 hover:bg-red-500 cursor-pointer"
                            >
                              <FontAwesomeIcon icon={faCircleXmark} />
                            </span>
                          )}
                      </div>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <p className="no-records">No sessions found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          next={nextPage}
          prev={previousPage}
          first={indexOfFirstRecord}
          last={indexOfLastRecord}
          total={filteredRecords.length}
        />
      </div>
    </section>
  )
}

export default ClaimSessions
