import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../hooks/useAuth'
import { useState } from 'react'

function ReportConfirm({ show }) {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center"
      style={{ zIndex: 999 }}
    >
      <div className="bg-slate-700/50 w-full h-full">&nbsp;</div>
      <div className="flex flex-col absolute p-6 bg-white w-[550px] rounded-xl shadow-md">
        <span className="flex justify-center mb-6 text-blue-500">
          <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 70 }} />
        </span>
        <h2 className="text-xl font-bold text-center pb-2">
          This report is now running, please allow a few minutes for it to
          complete. Once it has finished you will receive an email with the
          report attached.
        </h2>
        <div className="mt-6 flex justify-center">
          <button
            className="btn green w-full"
            onClick={() => {
              show(false)
            }}
          >
            Ok!
          </button>
        </div>
      </div>
    </div>
  )
}
export default ReportConfirm
