import {
  faBell,
  faCog,
  faUser,
  faRightFromBracket,
  faArrowRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from '../hooks/useAuth'

import { Fragment, useState, useEffect } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'

import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react'
import useLogout from '../hooks/useLogout'
import SystemNotification from './SystemNotification'

import useAxiosPrivate from '../hooks/useAxiosPrivate'

function Header({ collapsed }) {
  const { auth, setShowUpdate, notNow } = useAuth()
  const logout = useLogout()
  const axios = useAxiosPrivate()
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState(false)

  const [specialitySearchQuery, setSpecialitySearchQuery] = useState('')
  const [specialitySearchResults, setSpecialitySearchResults] = useState([])
  const [mediaWidth, setMediaWidth] = useState(window.innerWidth)

  useEffect(() => {
    setSearchResults([])
    if (searchQuery.length >= 3) {
      runSearch()
    }
  }, [searchQuery])

  useEffect(() => {
    setSpecialitySearchResults([])
    if (specialitySearchQuery.length >= 3) {
      runSpecialitySearch()
    }
  }, [specialitySearchQuery])

  useEffect(() => {
    const handleResize = () => {
      setMediaWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const headerStyles = {
    zIndex: collapsed || mediaWidth <= 1020 ? 10 : mediaWidth <= 1020 ? 10 : 99,
    width: collapsed
      ? 'calc(100% - 4rem)'
      : mediaWidth <= 1020
      ? 'calc(100% - 4rem)'
      : 'calc(100% - 21rem)',
  }

  const runSpecialitySearch = async () => {
    try {
      const response = await axios.get(
        `/search/specialities/${specialitySearchQuery}`
      )
      if (!response.data.error) {
        setSpecialitySearchResults(response.data.result)
      }
    } catch (error) {}
  }

  const runSearch = async () => {
    try {
      const response = await axios.get(`/search/clients/${searchQuery}`)
      if (!response.data.error) {
        setSearchResults(response.data.result)
      }
    } catch (error) {}
  }



  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'local') {
      if (notNow === true) {
        const notifications = setInterval(async () => {
          await fetchNotifications()
        }, 20000)

        const appUpdate = setInterval(async () => {
          await checkForUpdate(false)
        }, 300000)

        return () => {
          clearInterval(notifications)
          clearInterval(appUpdate)
        }
      } else {
        fetchNotifications()
        checkForUpdate(true)
        const notifications = setInterval(async () => {
          await fetchNotifications()
        }, 20000)

        const appUpdate = setInterval(async () => {
          await checkForUpdate(false)
        }, 20000)

        return () => {
          clearInterval(notifications)
          clearInterval(appUpdate)
        }
      }
    }
  }, [notNow])

  const viewMessage = (id, thread_reference, name) => {
    return navigate(`/clients/${id}/messages?thread=${thread_reference}`, {state:{name:name, id: id, reference: thread_reference}})
  }

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('/notifications/user/0')
      if (response.data.result.length > 0) {
        setUnreadNotifications(true)
      }
      setNotifications(response.data.result)
    } catch (error) {}
  }

  const checkForUpdate = async (reload = false) => {
    try {
      if (reload) {
        const response = await axios.get(`/users/run-update`)
        if (!response.data.error) {
          setShowUpdate(false)
          return
        }
      }
      const response = await axios.get(`/users/check-for-update`)
      if (response.data.result && response.data.result.updated) {
        setShowUpdate(true)
      }
    } catch (error) {}
  }

  const clearNotifications = async () => {
    try {
      const response = await axios.post(
        `/notifications/clear/user/${auth.user.id}`,
        notifications
      )
      setNotifications([])
    } catch (error) {}
  }

  const clearNotificationFn = async (selected) => {
    try {
      await axios.post(
        `/notifications/${selected.id}/clear/user/${auth.user.id}`
      )

      setNotifications(
        notifications.filter((notification) => {
          return notification.id !== selected.id
        })
      )
      if(selected.thread_reference !== null)
      {
        viewMessage(selected.client.id, selected.thread_reference, selected.client.name);
      }
    } catch (error) {}
  }

  const viewClient = (id) => {
    setSearchQuery('')
    setSearchResults([])
    navigate(`/clients/${id}`)
  }

  return (
    <header
      className="bg-white rounded-xl w-full p-4 shadow-md flex justify-between items-center fixed"
      style={headerStyles}
    >
      <div className="flex items-center relative">
        <input
          type="text"
          id="main_search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border border-gray-200 rounded-md h-9 w-60 px-2 text-sm text-slate-800"
          placeholder="Search"
        />
        <span
          className="flex justify-center items-center bg-slate-100 rounded-md text-slate-500 h-9 w-9 mx-2  hover:bg-slate-200 cursor-pointer"
          onClick={() => {
            setSearchQuery('')
            setSearchResults([])
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        {searchResults.length > 0 ? (
          <div
            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px]"
            style={{ top: 'calc(100% + 30px)' }}
          >
            <ul>
              {searchResults.map((result) => (
                <li
                  key={result.id}
                  className="flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer"
                  onClick={() => viewClient(result.id)}
                >
                  <span className="font-bold text-lg">{result.name}</span>
                  <div className="flex">
                    <span className="font-semibold mr-1">Ref:</span>
                    {result.reference}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : searchQuery.length > 0 ? (
          <div
            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px]"
            style={{ top: 'calc(100% + 30px)' }}
          >
            <ul>
              <li className="flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer">
                <span className="font-bold text-lg">No results found</span>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      <div className="flex items-center">
        <div className="flex items-center">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton
                className={`flex items-center rounded-md cursor-pointer ml-2`}
              >
                <div className="flex justify-center items-center w-5 h-5 fill-slate-500 mx-2 text-lg hover:fill-slate-700 cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M327.5 85.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 128l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 128l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 64 426.8 7.5C425.1 3 420.8 0 416 0s-9.1 3-10.8 7.5L384 64 327.5 85.2zM205.1 73.3c-2.6-5.7-8.3-9.3-14.5-9.3s-11.9 3.6-14.5 9.3L123.3 187.3 9.3 240C3.6 242.6 0 248.3 0 254.6s3.6 11.9 9.3 14.5l114.1 52.7L176 435.8c2.6 5.7 8.3 9.3 14.5 9.3s11.9-3.6 14.5-9.3l52.7-114.1 114.1-52.7c5.7-2.6 9.3-8.3 9.3-14.5s-3.6-11.9-9.3-14.5L257.8 187.4 205.1 73.3zM384 384l-56.5 21.2c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L384 448l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L448 448l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L448 384l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L384 384z" />
                  </svg>
                </div>
              </MenuButton>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute -right-12 mt-2 w-[400px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-4">
                  <h2 className="font-semibold text-xl mb-4">
                    Speciality Search
                  </h2>
                  <MenuItem disabled>
                    {({ focus }) => (
                      <div className="flex items-center relative">
                        <input
                          type="text"
                          id="main_search"
                          value={specialitySearchQuery}
                          onChange={(e) =>
                            setSpecialitySearchQuery(e.target.value)
                          }
                          className="border border-gray-200 rounded-md h-9 w-60 px-2 text-sm text-slate-800"
                          placeholder="Search"
                        />
                        <span
                          className="flex justify-center items-center bg-slate-100 rounded-md text-slate-500 h-9 w-9 mx-2  hover:bg-slate-200 cursor-pointer"
                          onClick={() => {
                            setSpecialitySearchQuery('')
                            setSpecialitySearchResults([])
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </span>

                        {specialitySearchResults.length > 0 ? (
                          <div
                            className="absolute -right-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px]"
                            style={{ top: 'calc(100% + 30px)' }}
                          >
                            <h2 className="font-bold mb-3">
                              Specialities Found:
                            </h2>
                            <ul>
                              {specialitySearchResults.map((result) => (
                                <li
                                  key={result.id}
                                  className="flex flex-col mb-2 cursor-pointer"
                                >
                                  <span className="text-base font-semibold">
                                    {result.title}:
                                  </span>
                                  <ul>
                                    {result.users_relationship.map(
                                      (user, userIndex) => {
                                        return (
                                          <li key={userIndex}>{user.name}</li>
                                        )
                                      }
                                    )}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : searchQuery.length > 0 ? (
                          <div
                            className="absolute -left-4 bg-white p-3 pb-1 rounded-lg shadow-md min-w-[500px]"
                            style={{ top: 'calc(100% + 30px)' }}
                          >
                            <ul>
                              <li className="flex flex-col p-3 bg-blue-100 rounded mb-2 hover:bg-blue-200/80 cursor-pointer">
                                <span className="font-bold text-lg">
                                  No results found
                                </span>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton
                className={`flex items-center rounded-md cursor-pointer ml-2 ${
                  notifications.length > 0 && unreadNotifications ? 'ping' : ''
                }`}
                onClick={() => setUnreadNotifications(false)}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  className="flex justify-center items-center text-slate-500 p-1.5 px-1 mx-2 text-lg hover:text-slate-700 cursor-pointer"
                />
              </MenuButton>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute -right-12 mt-2 w-[400px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-4">
                  <h2 className="font-semibold text-xl mb-4">Notifications</h2>
                  <MenuItem>
                    {({ focus }) => (
                      <SystemNotification
                        notificationList={notifications}
                        clearNotification={clearNotificationFn}
                      />
                    )}
                  </MenuItem>
                  <div className="flex justify-between mt-2 items-center pt-2 border-t border-gray-200">
                    <span
                      className="text-gray-500 text-sm cursor-pointer hover:underline"
                      onClick={clearNotifications}
                    >
                      {notifications.length > 0 ? 'Mark all as read' : ''}
                    </span>
                    <Link
                      className="text-blue-500 font-semibold hover:text-blue-600"
                      to="/notifications"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </MenuItems>
            </Transition>
          </Menu>

          {/* Settings */}
          {/* <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="flex items-center rounded-md cursor-pointer">
                <FontAwesomeIcon
                  icon={faCog}
                  className="flex justify-center items-center text-slate-500 p-1.5 mx-2 text-lg hover:text-slate-700 cursor-pointer"
                />
              </MenuButton>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute -right-12 mt-2 w-[400px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-2">
                  <MenuItem>
                    {({ focus }) => <span>I am a setting</span>}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu> */}
        </div>
        <span className="w-0.5 h-full bg-slate-200 relative flex mx-2">
          &nbsp;
        </span>

        <Menu as="div" className="relative inline-block text-left">
          <div>
            <MenuButton className="flex items-center rounded-md cursor-pointer ml-2">
              <span className="font-semibold text-sm mr-2">
                Welcome Back, {auth?.user.name}
              </span>
              <img
                src={auth.user.profile_pic}
                alt="User Profile"
                className="w-7 h-7 rounded-full border border-gray-100"
              />
            </MenuButton>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="p-2">
                <MenuItem>
                  {({ focus }) => (
                    <NavLink
                      to={`/users/${auth.user.id}`}
                      className={`${
                        focus ? 'bg-blue-500 text-white' : 'text-slate-800'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      My Profile
                    </NavLink>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <button
                      onClick={logout}
                      className={`${
                        focus ? 'bg-blue-500 text-white' : 'text-slate-800'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <FontAwesomeIcon
                        icon={faRightFromBracket}
                        className="mr-2"
                      />
                      Logout
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </header>
  )
}

export default Header
